<template>
  <div class="bg-fixed bg-contain bg-hero-blue">
    <Nav></Nav>
    <DownloadHeader class="bg-hero-blue"></DownloadHeader>

    <Footer class="bg-hero-blue px-5"></Footer>
    <Copyright></Copyright>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Copyright from "../components/Copyright";
import DownloadHeader from "../components/DownloadHeader";

export default {
  components: {
    DownloadHeader,
    Footer,
    Nav,
    Copyright
  }
};
</script>

<style>
.bg-hero-blue {
  background: #033480;
}
</style>
