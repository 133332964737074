<template>
  <div class="container mx-auto flex flex-row py-16">
    <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
      <h1 class="text-xl text-blue-900 font-bold">
        {{ $t("policy_content001") }}
      </h1>
      <p class="text-base italic">
        {{ $t("policy_content002") }}
      </p>
      <p class="text-base italic">
        {{ $t("policy_content003") }}
      </p>
      <br />
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content004") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content005") }}
      </p>

      <p class="text-base my-4 ">
        {{ $t("policy_content006") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content007") }}
      </p>
      <br />
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content008") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content009") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content010") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content011") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content012") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content013") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content014") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content015") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content016") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content017") }}
        </p>
      </div>

      <p class="text-base my-4 ">
        {{ $t("policy_content018") }}
      </p>

      <p class="text-base my-4 ">
        {{ $t("policy_content019") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content020") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content021") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content022") }}
        </p>
      </div>

      <p class="text-base my-4 ">
        {{ $t("policy_content023") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content024") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content025") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content026") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content027") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content028") }}
        </p>
      </div>

      <p class="text-base my-4 ">
        {{ $t("policy_content029") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content030") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content031") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content032") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content033") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content034") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content035") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content036") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content037") }}
        </p>
      </div>
      <p class="text-base my-4 ">
        {{ $t("policy_content038") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content039") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content040") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content041") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content042") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content043") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content044") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content045") }}
        </p>
      </div>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content046") }}
      </p>

      <p class="text-base my-4 ">
        {{ $t("policy_content047") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content048") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content049") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content050") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content051") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content052") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content053") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content054") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content055") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content056") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content057") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content058") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content059") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content060") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content061") }}
        </p>
      </div>
      <p class="text-base my-4 ">
        {{ $t("policy_content062") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content063") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content064") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content065") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content066") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content067") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content068") }}
        </p>
      </div>

      <p class="text-base my-4 ">
        {{ $t("policy_content069") }}
      </p>

      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content070") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content071") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content072") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content073") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content074") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content075") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content076") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content077") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content078") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content079") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content080") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content081") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content082") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content083") }}
        </p>
      </div>
      <p class="text-base my-4 ">
        {{ $t("policy_content084") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content085") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content086") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content087") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content088") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content089") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content090") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content091") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content092") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content093") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content094") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content095") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content096") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content097") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content098") }}
        </p>
      </div>
      <p class="text-base my-4 ">
        {{ $t("policy_content099") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content100") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content101") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content102") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content103") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content104") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content105") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content106") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content107") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content108") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content109") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content110") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content111") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content112") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content113") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content114") }}
        </p>
      </div>

      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content115") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content116") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content117") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content118") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content119") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content120") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content121") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content122") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content123") }}
      </p>
      <div class="ml-4">
        <p class="text-base my-4 ">
          {{ $t("policy_content124") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content125") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content126") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content127") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content128") }}
        </p>
        <p class="text-base my-4 ">
          {{ $t("policy_content129") }}
        </p>
      </div>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content130") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content131") }}
      </p>
      <p class="text-base mt-4 ">
        {{ $t("policy_content132") }}
      </p>
      <p class="text-base mb-4 hover:text-blue-900 hover:underline">
        <a href="https://shootapp.my/support">
        {{ $t("policy_content133") }}
        </a>
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content134") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content135") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content136") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content137") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content138") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content139") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content140") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content141") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content142") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content143") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content144") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content145") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content146") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content147") }}
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content148") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content149") }}
      </p>
      <p class="text-base mt-2 ">
        {{ $t("policy_content150") }}
      </p>
      <p class="text-base mb-4 hover:text-blue-900  hover:underline">
        <a href="https://shootapp.my/support">
        {{ $t("policy_content151") }}
        </a>
      </p>
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("policy_content152") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content153") }}
      </p>
      <p class="text-base my-4 ">
        {{ $t("policy_content154") }}
      </p>
      <!-- 
      

      <p class="text-xl text-blue-900 font-bold">
        {{ $t("privacy13") }}
      </p>
      <p class="text-base">
        {{ $t("privacy14") }}
      </p>
      <br />
      <p class="text-base font-bold text-blue-900">
        <i>{{ $t("privacy15") }}</i>
      </p>
      <p class="text-base">
        {{ $t("privacy16") }}
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy17") }}
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy18") }}
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy19") }}
      </p>
      <br />
      <p class="text-base font-bold text-blue-900">
        <i>{{ $t("privacy20") }}</i>
      </p>
      <p class="text-base">
        {{ $t("privacy21") }}
      </p>
      <ol class="ml-4">
        <li>{{ $t("privacy22") }}</li>
        <li>{{ $t("privacy23") }}</li>
        <li>{{ $t("privacy24") }}</li>
        <li>{{ $t("privacy25") }}</li>
        <li>{{ $t("privacy26") }}</li>
        <li>{{ $t("privacy27") }}</li>
        <li>{{ $t("privacy28") }}</li>
        <li>{{ $t("privacy29") }}</li>
        <li>{{ $t("privacy30") }}</li>
        <li>{{ $t("privacy31") }}</li>
        <li>{{ $t("privacy32") }}</li>
      </ol>
      <br />
      <p class="text-base">
        {{ $t("privacy33") }}
      </p>
      <br />
      <p class="text-base font-bold text-blue-900">
        <i>{{ $t("privacy34") }}</i>
      </p>
      <p class="text-base">
        {{ $t("privacy35") }}
      </p>
      <br />
      <p class="text-base font-bold text-blue-900">
        <i>{{ $t("privacy36") }}</i>
      </p>
      <p class="text-base">
        {{ $t("privacy37") }}
      </p>
      <ol class="ml-4">
        <li>{{ $t("privacy38") }}</li>
        <li>{{ $t("privacy39") }}</li>
        <li>{{ $t("privacy40") }}</li>
      </ol>
      <br />

      <p class="text-xl text-blue-900 font-bold">
        {{ $t("privacy41") }}
      </p>

      <p class="text-base">
        {{ $t("privacy42") }}
      </p>
      <br />
      <p class="text-base text-blue-900 font-bold">
        {{ $t("privacy43") }}
      </p>

      <p class="text-base">
        {{ $t("privacy44") }}
      </p>
      <br />
      <p class="text-base ml-5">
        {{ $t("privacy45") }}
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy46") }}
      </p>
      <br />
      <p class="text-base ml-5">
        {{ $t("privacy47") }}
      </p>
      <br />
      <p class="text-base ml-5">
        {{ $t("privacy48") }}
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy49") }}
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy50") }}
      </p>
      <br />
      <p class="text-base ml-5">
        {{ $t("privacy51") }}
      </p>
      <br />
      <p class="text-base ml-5">
        {{ $t("privacy52") }}
      </p>
      <br />
      <p class="text-base ml-5">
        {{ $t("privacy53") }}
      </p>
      <br />
      <p class="text-base ml-5">
        {{ $t("privacy54") }}
      </p>
      <br />

      <p class="text-base ml-10">
        {{ $t("privacy55") }}
      </p>
      <br />
      <p class="text-base ml-10">
        {{ $t("privacy56") }}
      </p>
      <br />

      <p class="text-xl text-blue-900 font-bold">
        {{ $t("privacy57") }}
      </p>
      <p class="text-base">
        {{ $t("privacy58") }}
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy59") }}
      </p>
      <br />
      <p class="text-xl text-blue-900 font-bold">
        {{ $t("privacy60") }}
      </p>
      <p class="text-base">
        {{ $t("privacy61") }}
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy62") }}
      </p>
      <br />
      <p class="text-base font-bold">
        {{ $t("privacy63") }}
      </p>
      <p class="text-base">
        {{ $t("privacy64") }}
      </p>
      <p class="text-base">
        {{ $t("privacy65") }}
      </p>
      <br /><br />
      <p class="text-base font-bold">
        <i>{{ $t("privacy66") }}</i>
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy67") }} 
      </p> -->
      <br />
      <br />
      <br />
      <h1 class="text-xl text-blue-900 font-bold">
        {{ $t("privacy69") }}
      </h1>

      <p class="text-base">
        {{ $t("privacy70") }}
      </p>
      <br />
      <p class="text-xl text-blue-900 font-bold">
        {{ $t("privacy71") }}
      </p>
      <p class="text-base">
        {{ $t("privacy72") }}
      </p>
      <br />
      <p class="text-xl text-blue-900 font-bold">
        {{ $t("privacy73") }}
      </p>
      <p class="text-base">
        {{ $t("privacy74") }}
      </p>

      <ul class="list-disc ml-5">
        <li>{{ $t("privacy75") }}</li>
        <li>{{ $t("privacy76") }}</li>
        <li>{{ $t("privacy77") }}</li>
        <li>{{ $t("privacy78") }}</li>
        <li>{{ $t("privacy79") }}</li>
      </ul>
      <br />
      <p class="text-xl text-blue-900 font-bold">
        {{ $t("privacy80") }}
      </p>
      <p class="text-base">
        {{ $t("privacy81") }}
      </p>
      <br />
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("privacy82") }}
      </p>
      <p class="text-base">
        {{ $t("privacy83") }}
      </p>
      <br />
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("privacy84") }}
      </p>
      <p class="text-base">
        {{ $t("privacy85") }}
      </p>
      <br />
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("privacy86") }}
      </p>
      <p class="text-base">
        {{ $t("privacy87") }}
      </p>
      <br />
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("privacy88") }}
      </p>
      <p class="text-base">
        {{ $t("privacy89") }}
      </p>
      <br />
      <p class="text-base">
        {{ $t("privacy90") }}
      </p>
      <br />
      <p class="text-lg text-blue-900 font-bold">
        {{ $t("privacy91") }}
      </p>
      <p class="text-base">
        {{ $t("privacy92") }}
      </p>
      <br />
      <p class="text-base font-bold">
        <u>{{ $t("privacy93") }}</u>
      </p>
      <p class="text-base">
        {{ $t("privacy94") }}
      </p>
      <p class="text-base">
        {{ $t("privacy95") }}
      </p>
      <br /><br /><br /><br /><br /><br />
      <small>
        <i>{{ $t("privacy96") }}</i>
      </small>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
