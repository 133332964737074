<template>
  <div id="faq">
    <!-- Banner (blue) -->
    <div class="bg-hero-blue h-auto text-white mx-auto mb-5">
      <div class="container mx-auto flex flex-row py-16">
        <!-- Icon -->
        <div class="py-10">
          <svg
            class="h-10 w-10"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 461.87 459.5"
            fill="white"
          >
            <title>Shoot!-UI</title>
            <g id="_5xmaFq" data-name="5xmaFq">
              <path
                class="cls-1"
                d="M81.41,378.6V270.28c-6-.44-11.19-.58-16.35-1.25-22.57-2.91-40-14.06-52.77-32.9C3.88,223.68.08,209.88.05,195q-.13-60.3,0-120.58C0,54.52,6.78,37,20.57,22.87,34,9.13,50.55,1.2,70,.37,77.12.07,84.22,0,91.31,0Q203.54,0,315.78,0C327,0,338,1,348.42,5.36a77.64,77.64,0,0,1,24.7,16.18,70.74,70.74,0,0,1,20.35,36.73A65,65,0,0,1,395,71.82q.17,63,0,126c-.07,17.87-6.61,33.4-18.48,46.68-15.24,17.06-34.37,25.34-57.21,25.32q-55.27-.07-110.56.11a12.14,12.14,0,0,0-7.28,2.9c-18.11,15.91-36.06,32-54,48.09-12.18,10.92-24.25,21.95-36.43,32.87-9.38,8.42-18.84,16.74-28.27,25.1ZM345,183.57c-3.4-3.1-6.7-5.86-9.63-9-.73-.77-.76-2.89-.26-4,1.33-3,3.41-5.68,4.68-8.7,5.42-12.88,6.2-26.36,3.95-39.94-2.22-13.38-8.78-24.36-20.05-32.23-11.53-8-24.33-9.7-38-7.77a46.54,46.54,0,0,0-37.72,33,79.92,79.92,0,0,0-1.37,37.32c2.43,12.18,8.11,22.76,18.64,29.86,16.75,11.28,34.5,12.28,52.67,3.63,2.81-1.34,4.42-1.15,6.39,1.09,2.19,2.5,4.81,4.62,7.08,7.07,1.63,1.76,2.89,1.7,4.42-.07C338.64,190.54,341.62,187.28,345,183.57Zm-132.48,4.87h24L199.77,84.5c-7.65,0-15-.12-22.37.11a4.73,4.73,0,0,0-3.42,2.5c-2.77,7.2-5.2,14.52-7.81,21.78C159.33,128,152.43,147,145.61,166.08c-2.63,7.35-5.08,14.76-7.69,22.36h22.86c2.09-6.22,4.26-12.18,6.06-18.25.73-2.48,1.9-3.19,4.39-3.16,10.28.13,20.58.21,30.86,0,3.06-.08,4.12,1.21,4.87,3.74C208.72,176.66,210.63,182.54,212.47,188.44ZM79.25,145.26h5.27c12.24,0,24.48-.07,36.71.05,2.88,0,4-.88,3.88-3.82-.18-3.74-.19-7.51,0-11.25.15-2.93-.94-3.88-3.84-3.85-11.82.13-23.64,0-35.46,0H79.6v-23.1h51.21V85H57.37V188.39H79.25Z"
              />
              <path
                class="cls-1"
                d="M420.3,76.27a77.73,77.73,0,0,1,22.24,18.12c11.7,13.41,18.3,29,19.21,46.84.21,4.1.08,8.23.08,12.34,0,37.7-.07,75.39,0,113.08,0,16.53-3.69,31.76-14.31,44.81A65.5,65.5,0,0,1,421,330.76c-14.52,6-29.68,8.37-45.3,8.32a18.09,18.09,0,0,0-2.08.09,3.92,3.92,0,0,0-.91.44c-1.27,39.62-2.55,79.34-3.85,119.89-1.59-1.48-2.7-2.42-3.7-3.45q-22.31-22.8-44.58-45.63-24.24-25-48.37-50c-5.11-5.3-10.07-10.74-15.28-15.92a7.46,7.46,0,0,0-4.62-2q-24.15-.6-48.3-.86c-9-.11-18.09,0-27.13-.07-1.31,0-2.62-.14-4.54-.26,6.27-6.58,12-12.72,17.87-18.74,9.1-9.35,18.45-18.48,27.36-28,3.25-3.47,6.59-4.64,11.26-4.62,36.58.15,73.16.31,109.74-.11,21.6-.25,41.52-6.19,57.1-22.14,12.89-13.2,20.66-29.47,22.16-47.68,1.54-18.65.89-37.48,1.13-56.23.11-9.32,0-18.64.07-28q.24-27.3.62-54.61A47.12,47.12,0,0,1,420.3,76.27Z"
              />
              <path
                class="cls-1"
                d="M317.25,158.4c-3.13-3.72-6-7.13-8.66-10.31l-12.14,12,9.3,9.23c-9.25,6.38-25.28,2.65-32-7.34s-7.27-21.54-6.31-33.12c.61-7.38,2.72-14.3,7.58-20.21,10.15-12.34,36.05-12.22,43.88,6.14C323.25,125,324.3,147.78,317.25,158.4Z"
              />
              <path
                class="cls-1"
                d="M199.73,148.58H174.17l12.36-38.15.91-.18Z"
              />
            </g>
          </svg>
        </div>
        <!-- Title & Descriptions -->
        <div class="flex flex-col">
          <h1 class="pt-8 pl-3 text-4xl md:text-6xl font-bold">
            {{ $t("frequent_title") }} (FAQ)
          </h1>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div class="mx-10 pt-10 pb-20">
      <div class="container mx-auto mb-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq1_question") }}</p>
          </div>
          <div class="pb-5">
            <p>
              {{ $t("faq1_answer") }}
            </p>
          </div>
          <!-- Link -->
          <div class="flex flex-row overflow-hidden">
            <p class="pr-2">Google Play:&nbsp;</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.ingres.shoot "
            >
              <p class="text-blue-900 underline">
                https://play.google.com/store/apps/details?id=com.ingres.shoot
              </p>
            </a>
          </div>
          <div class="flex flex-row overflow-hidden">
            <p class="pr-2">App Store:&nbsp;</p>
            <a
              href="https://apps.apple.com/us/app/shoot-bola-sepak-malaysia/id1567181334"
            >
              <p class="text-blue-900 underline">
                https://apps.apple.com/us/app/shoot-bola-sepak-malaysia/id1567181334
              </p>
            </a>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq2_question") }}</p>
          </div>
          <div class="">
            <p>
              {{ $t("faq2_answer") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq3_question") }}</p>
          </div>
          <div class="pb-5">
            <p>
              {{ $t("faq3_answer") }}
            </p>
          </div>
          <!-- Link -->
          <div class="flex flex-row overflow-hidden">
            <p class="pr-2">Google Play:&nbsp;</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.ingres.shoot "
            >
              <p class="text-blue-900 underline">
                https://play.google.com/store/apps/details?id=com.ingres.shoot
              </p>
            </a>
          </div>
          <div class="flex flex-row overflow-hidden">
            <p class="pr-2">App Store:&nbsp;</p>
            <a
              href="https://apps.apple.com/us/app/shoot-bola-sepak-malaysia/id1567181334"
            >
              <p class="text-blue-900 underline">
                https://apps.apple.com/us/app/shoot-bola-sepak-malaysia/id1567181334
              </p>
            </a>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq4_question") }}</p>
          </div>
          <div class="">
            <p>
              {{ $t("faq4_answer") }}
              <bold class="font-bold">shoot@shootapp.my</bold>
              {{ $t("faq4_answer_1") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq5_question") }}</p>
          </div>
          <div class="">
            <p>
              {{ $t("faq5_answer") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq6_question") }}</p>
          </div>
          <div class="">
            <p>
              {{ $t("faq6_answer") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq7_question") }}</p>
          </div>
          <div class="">
            <p>
              {{ $t("faq7_answer") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq8_question") }}</p>
          </div>
          <div class="">
            <p>
              {{ $t("faq8_answer") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq9_question") }}</p>
          </div>
          <div class="">
            <p>
              {{ $t("faq9_answer") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq10_question") }}</p>
          </div>
          <div class="">
            <p>
              {{ $t("faq10_answer") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq11_question") }}</p>
          </div>
          <div class="">
            <p>
              {{ $t("faq11_answer") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 m-6">
        <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
          <div class="font-semibold text-2xl">
            <p>{{ $t("faq12_question") }}</p>
          </div>
          <div class="pb-5">
            <p>
              {{ $t("faq12a_answer") }}
              <bold class="font-bold">shoot@shootapp.my</bold>
              {{ $t("faq12b_answer") }}
            </p>
          </div>
            <div class="pb-5">
              <p>
              {{ $t("faq12c_answer") }}
            </p>
            <p>
              {{ $t("faq12d_answer") }}
            </p>
            <p>
              {{ $t("faq12e_answer") }}
            </p>
            <p>
              {{ $t("faq12f_answer") }}
            </p>
            </div>
            <div class="pb-5">
            <p>
              {{ $t("faq12g_answer") }}
            </p>
          </div>
          
        </div>
      </div>

      <div class="container mx-auto my-5 m-6">
        <p>{{ $t("latest_update") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  components: {},
  methods: {},
};
</script>
<style>
.bg-hero-blue {
  background: rgba(3, 52, 128, 1);
}
</style>
