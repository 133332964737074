<template>
  <section>
    <div class="flex flex-col">
      <!-- Content -->
      <div class="flex flex-wrap flex-col md:flex-row items-center">
        <!--Left Col-->
        <div
          class="
            w-1/2
            py-5
            px-10
            lg:pl-24
            flex flex-col
            w-full
            md:w-3/5
            text-center
            md:text-left
          "
        >
          <!-- Title -->
          <h1
            class="
              my-4
              lg:pt-24
              text-3xl
              lg:text-7xl
              font-bold
              text-white
              leading-tight
            "
          >
            {{ $t("advertise_title") }}
          </h1>
          <!-- Description -->
          <p
            class="
              leading-normal
              text-xl text-justify
              font-light
              text-white
              mb-8
            "
          >
            {{ $t("advertise_title_description") }}
          </p>
        </div>
        <!--Right Col-->
        <div class="flex md:w-2/5 justify-center">
          <img
            class="w-3/5 h-2/5 object-center pt-0 lg:pt-8 pb-8"
            src="@/assets/bg/ads-phone.png"
          />
        </div>
      </div>
      <!-- Arrow Bottom -->
      <div class="relative">
        <a href="#advertiseWithUs">
          <svg
            class="h-10 mx-auto -mt-10"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 316.1 143.58"
          >
            <path
              fill="#fff"
              fill-opacity="1"
              id="Path_5"
              data-name="Path 5"
              class="cls-1"
              d="M0,143.49s27.81, 0,82.78-84.11,129-67.41,164.66, 0,68.66,84.11,68.66,84.11Z"
            />
            <path
              fill="#1c2343"
              fill-opacity="1"
              id="Icon_awesome-arrow-down"
              data-name="Icon awesome-arrow-down"
              class="cls-2 animate-bounce"
              d="M189.36,76.48,191.87,79a2.71,2.71,0,0,1,0,3.83l0,0-22,22a2.71,2.71,0,0,1-3.83,0l0,0-22-22A2.7,2.7,0,0,1,144,79l0,0,2.52-2.52a2.72,2.72,0,0,1,3.84,0l0,0,13,13.63V57.64a2.71,2.71,0,0,1,2.7-2.71h3.64a2.71,2.71,0,0,1,2.71,2.7h0V90.15l13-13.63a2.69,2.69,0,0,1,3.82-.11l.06.06Z"
            />
          </svg>
        </a>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style scoped>
section {
  background: rgba(3, 52, 128, 1);
}
</style>
