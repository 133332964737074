<template>
  <div
    class="
      w-full
      text-center
      items-center
      justify-center
      h-auto
      my-auto
      flex flex-col
      px-5
    "
  >
    <div class="flex flex-col">
      <!-- Title -->
      <div class="w-full pt-40">
        <h2 class="text-4xl md:text-6xl leading-tight font-bold text-white">
          {{ $t("how_can_we_help") }}
        </h2>
      </div>
      <!-- Search bar -->
      <div class="py-10">
        <div class="relative">
          <input
            type="text"
            class="
              appearance-none
              block
              w-full
              bg-gray-200
              text-gray-700
              border border-yellow-500
              rounded
              py-4
              px-4
              leading-tight
              focus:outline-none
              focus:bg-white
              focus:border-gray-500
            "
            name=""
            placeholder="Start typing the issue..."
            id=""
          />
          <button
            type="submit"
            class="
              absolute
              bg-yellow-500
              hover:bg-teal-400
              text-white
              px-4
              py-2
              text-sm
              font-bold
              rounded
              absolute
              top-0
              right-0
              my-2
              mr-2
            "
          >
            <svg
              class="h-5 w-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="#F59E0B"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </div>
      </div>
      <!-- Choose text -->
      <div>
        <p class="leading-snug text-white">
          {{ $t("or_choose") }}
        </p>
      </div>
      <!-- FAQ & Facebook & Helpdesk -->
      <div class="flex flex-col md:flex-row justify-between mt-12 pb-40">
        <div
          class="
            w-full
            bg-white
            shadow-lg
            rounded-lg
            px-4
            py-6
            lg:p-8
            md:mx-2
            lg:mx-4
            mt-4
            md:mt-0
          "
        >
          <a href="/guide">
            <img src="@/assets/img/FAQ.png" alt="" class="mx-auto h-32" />
            <h4 class="text-xl font-bold leading-tight mt-8">FAQs</h4>
            <p class="text-gray-700 mt-2">
              {{ $t("faq_content") }}
            </p>
          </a>
        </div>
        <div
          class="
            w-full
            bg-white
            shadow-lg
            rounded-lg
            px-4
            py-6
            lg:p-8
            md:mx-2
            lg:mx-4
            mt-4
            md:mt-0
          "
        >
          <a href="/support">
            <img src="@/assets/img/helpdesk.png" alt="" class="mx-auto h-32" />
            <h4 class="text-xl font-bold leading-tight mt-8">Helpdesk</h4>
            <p class="text-gray-700 mt-2">
              {{ $t("helpdesk_content") }}
            </p>
          </a>
        </div>
      </div>
      <!-- Bouncy Arrow -->
      <div class="relative">
        <a href="#contact">
          <svg
            class="h-10 mx-auto -mt-10"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 316.1 143.58"
          >
            <path
              fill="#121212"
              fill-opacity="1"
              id="Path_5"
              data-name="Path 5"
              class="cls-1"
              d="M0,143.49s27.81, 0,82.78-84.11,129-67.41,164.66, 0,68.66,84.11,68.66,84.11Z"
            />
            <path
              fill="#fff"
              fill-opacity="1"
              id="Icon_awesome-arrow-down"
              data-name="Icon awesome-arrow-down"
              class="cls-2 animate-bounce"
              d="M189.36,76.48,191.87,79a2.71,2.71,0,0,1,0,3.83l0,0-22,22a2.71,2.71,0,0,1-3.83,0l0,0-22-22A2.7,2.7,0,0,1,144,79l0,0,2.52-2.52a2.72,2.72,0,0,1,3.84,0l0,0,13,13.63V57.64a2.71,2.71,0,0,1,2.7-2.71h3.64a2.71,2.71,0,0,1,2.71,2.7h0V90.15l13-13.63a2.69,2.69,0,0,1,3.82-.11l.06.06Z"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      pageheight: 0
    };
  },

  components: {}
};
</script>

<style></style>
