<template>
  <div class="bg-hero-blue h-auto text-white mx-auto mb-5">
    <div class="container mx-auto flex flex-row py-16">
      <!-- Icon -->
      <div class="py-10">
        <svg
          class="h-10 w-10"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 365.44 488.9"
          fill="white"
        >
          <title>Shoot!-UI</title>
          <g id="XJYqCq.tif">
            <path
              class="cls-1"
              d="M331.19,488.9H34.4C13.42,479-.06,464.48,0,439.21c.33-129.8.05-259.6.23-389.39C.28,18.58,18.79.34,50.19.22c57.7-.23,115.4-.46,173.09.33,9.46.13,21.56,2.7,27.83,8.87C290,47.58,327.48,87.09,365.44,126.16V454.54ZM213.24,30.7c-54.18,0-106-.08-157.91,0-21.79.05-24.71,3.15-24.72,26q-.08,188,0,376c0,22.54,3,25.62,25.09,25.64q126.48.12,253,0c23.5,0,26.3-2.82,26.31-26.37q.09-130.74,0-261.46c0-5.49-.67-11-1.11-17.62-21.48,0-41.67.22-61.86,0-35.43-.49-58.19-23.18-58.69-58.51C213,74,213.24,53.73,213.24,30.7Zm30.46,18c0,16.14-2.23,31.85.8,46.47,2,9.53,10.38,23.31,18.05,24.93,17.62,3.73,36.55,1.18,53.43,1.18Z"
            />
            <path class="cls-1" d="M92.69,215H273.26v28.23H92.69Z" />
            <path class="cls-1" d="M92.3,304.33V276.05h181v28.28Z" />
            <path class="cls-1" d="M92.17,365.32V337.25H212.29v28.07Z" />
          </g>
        </svg>
      </div>
      <!-- Title & Descriptions -->
      <div class="flex flex-col">
        <h1 class="pt-8 pl-3 text-4xl md:text-6xl font-bold">
          {{ $t("terms_and_condition") }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
