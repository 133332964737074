<template>
  <section>
    <div class="flex flex-col">
      <!-- Content -->
      <div class="flex">
        <div class="flex flex-wrap flex-col md:flex-row items-center">
          <!--Left Col-->
          <div
            class="w-1/2 pt-5 px-10 lg:pl-24 flex flex-col w-full md:w-3/6 text-center md:text-left"
          >
            <!-- Title -->
            <h1
              class="my-4 uppercase lg:pt-24 text-3xl lg:text-6xl font-bold text-white leading-tight"
            >
              <span> {{ $t("product_title") }} </span>
            </h1>
            <!-- Description -->
            <p
              class="text-xl text-justify font-light text-white mb-8"
            >
              {{ $t("product_content") }}
            </p>
            <!-- Google Play  -->
            <div class="flex md:justify-start justify-center md:mb-16">
              <div class="w-1/2 lg:w-1/4">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.ingres.shoot "
                >
                  <img
                    class="w-full mx-auto"
                    src="@/assets/logo/google-play-x.png"
                  />
                </a>
              </div>
              <div class="w-1/2 lg:w-1/4 ml-2">
                <a
                  target="_blank"
                  href="https://apps.apple.com/us/app/shoot-bola-sepak-malaysia/id1567181334"
                >
                  <img class="w-full mx-auto" src="@/assets/logo/ios.png" />
                </a>
              </div>
            </div>
          </div>
          <!--Right Col-->
          <div class="flex-1 md:w-3/6 pt-6 pr-6 items-center">
            <img
              class="w-full mt-16 object-center-bottom"
              src="@/assets/bg/shoot-2022.png"
            />
          </div>
        </div>
      </div>
      <!-- Arrow Bottom -->
      <div class="relative">
        <a href="#feature">
          <svg
            class="h-10 mx-auto -mt-10"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 316.1 143.58"
          >
            <path
              fill="#fff"
              fill-opacity="1"
              id="Path_5"
              data-name="Path 5"
              class="cls-1"
              d="M0,143.49s27.81, 0,82.78-84.11,129-67.41,164.66, 0,68.66,84.11,68.66,84.11Z"
            />
            <path
              fill="#1c2343"
              fill-opacity="1"
              id="Icon_awesome-arrow-down"
              data-name="Icon awesome-arrow-down"
              class="cls-2 animate-bounce"
              d="M189.36,76.48,191.87,79a2.71,2.71,0,0,1,0,3.83l0,0-22,22a2.71,2.71,0,0,1-3.83,0l0,0-22-22A2.7,2.7,0,0,1,144,79l0,0,2.52-2.52a2.72,2.72,0,0,1,3.84,0l0,0,13,13.63V57.64a2.71,2.71,0,0,1,2.7-2.71h3.64a2.71,2.71,0,0,1,2.71,2.7h0V90.15l13-13.63a2.69,2.69,0,0,1,3.82-.11l.06.06Z"
            />
          </svg>
        </a>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style scoped>
section {
  background: rgba(3, 52, 128, 1);
}
</style>
