<template>
  <div class="w-full h-auto bg-white flex flex-col px-10 md:px-24">
    <!-- Title -->
    <div class="pb-16 w-full">
      <h1 class="items-center text-4xl font-semibold">
        {{ $t("contact_us") }}
      </h1>
    </div>
    <!-- Forms -->
    <div class="">
      <div class="w-full">
        <!-- Full Name -->
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-lg text-black font-bold mb-2"
              for="grid-password"
            >
              {{ $t("full_name") }}*
            </label>
            <input
              class="appearance-none block w-full border border-black rounded py-3 px-4 mb-3 leading-tight"
              id="grid-password"
              type="text"
              placeholder="Jane Doe"
              v-model="fullName"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fullName"
            />
            <span
              class="text-red-400 text-sm"
              v-show="errors.has('fullName')"
              >{{ errors.first("fullName") }}</span
            >
          </div>
        </div>
        <!-- Company & Work Email -->
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-lg font-bold mb-2"
              for="grid-first-name"
            >
              {{ $t("company") }}*
            </label>
            <input
              class="appearance-none block w-full border border-black rounded py-3 px-4 mb-3 leading-tight"
              id="grid-first-name"
              type="text"
              placeholder="Company Sdn Bhd"
              v-model="company"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="company"
            />
            <span class="text-red-400 text-sm" v-show="errors.has('company')">{{
              errors.first("company")
            }}</span>
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-lg font-bold mb-2"
              for="grid-last-name"
            >
              {{ $t("gmail_address") }}*
            </label>
            <input
              class="appearance-none block w-full border border-black rounded py-3 px-4 leading-tight"
              id="grid-last-name"
              type="text"
              placeholder="mail@mail.com"
              v-model="email"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="email"
            />
            <span class="text-red-400 text-sm" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
          </div>
        </div>
        <!--Work Phone & Industry -->
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-lg font-bold mb-2"
              for="grid-first-name"
            >
              {{ $t("mobile_no") }}*
            </label>
            <input
              class="appearance-none block w-full border border-black rounded py-3 px-4 mb-3 leading-tight"
              id="grid-first-name"
              type="text"
              placeholder="01234567890"
              v-model="contactPhoneNumber"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="contactPhoneNumber"
            />
            <span
              class="text-red-400 text-sm"
              v-show="errors.has('contactPhoneNumber')"
              >{{ errors.first("contactPhoneNumber") }}</span
            >
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-lg font-bold mb-2"
              for="grid-last-name"
            >
              {{ $t("industry") }}*
            </label>
            <input
              class="appearance-none block w-full border border-black rounded py-3 px-4 leading-tight"
              id="grid-last-name"
              type="text"
              v-model="industry"
              v-validate="'required'"
              v-bind:placeholder="$t('industry_placeholder')"
              data-vv-validate-on="blur"
              name="industry"
            />
            <span
              class="text-red-400 text-sm"
              v-show="errors.has('industry')"
              >{{ errors.first("industry") }}</span
            >
          </div>
        </div>
        <!-- Country -->
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-lg font-bold mb-2"
              for="grid-state"
            >
              {{ $t("country") }}*
            </label>
            <div class="relative">
              <select
                v-model="country"
                name="country"
                v-validate="'required'"
                data-vv-validate-on="blur"
                class="block appearance-none w-full border border-black py-3 px-4 pr-8 rounded leading-tight"
                id="grid-state"
              >
                <option>Malaysia</option>
                <option>Indonesia</option>
                <option>Singapore</option>
              </select>
              <span
                class="text-red-400 text-sm"
                v-show="errors.has('country')"
                >{{ errors.first("country") }}</span
              >
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!-- Text Area-->
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-lg text-black font-bold mb-2"
              for="grid-password"
            >
              {{ $t("advertise_message") }}?*
            </label>
            <textarea
              class="appearance-none block h-36 w-full border border-black rounded py-3 px-4 mb-3 leading-tight"
              id="grid-password"
              type="text"
              placeholder="Write here"
              v-model="messages"
              name="messages"
              v-validate="'required'"
              data-vv-validate-on="blur"
            />
            <span
              class="text-red-400 text-sm"
              v-show="errors.has('messages')"
              >{{ errors.first("messages") }}</span
            >
          </div>
        </div>
        <div class="w-full md:w-3/4">
          <h1 class="text-lg">
            {{ $t("ads_policy_pt_1") }}
            <a href="policy" class="text-blue-500"> {{ $t("policy_title") }}</a>
            {{ $t("ads_policy_pt_2") }}
          </h1>
        </div>
        <div
          class="w-full flex py-6 justify-center md:justify-end pb-10 md:pb-24"
        >
          <button
            @click="onSubmit"
            class="bg-blue-900 hover:bg-white text-white hover:text-blue-900 font-bold py-2 uppercase px-4 rounded"
            type="submit"
          >
            {{ $t("submit_btn") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  data: function () {
    return {
      industry: "",
      country: "",
      fullName: "",
      company: "",
      email: "",
      contactPhoneNumber: "",
      messages: "",
    };
  },
  methods: {
    onSubmit() {
      this.$validator
        .validate()
        .then((valid) => {
          if (!valid) {
            return;
          }
          console.log(this.name);
          console.log(this.email);
          console.log(this.contactPhoneNumber);
          console.log(this.messages);
          console.log(this.industry);
          console.log(this.messages);
          console.log(this.messages);
          firebase
            .firestore()
            .collection("campaign-partner")
            .add({
              full_name: this.fullName,
              email: this.email,
              contact_no: this.contactPhoneNumber,
              messages: this.messages,
              company: this.company,
              country: this.country,
              industry: this.industry,
              created_at: new Date(),
            })
            .then((result) => {
              console.log(result);
              this.$alert("We'll get back to you", "Success", "success");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
section {
  background: rgba(3, 52, 128, 1);
}
</style>
