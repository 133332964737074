import en from "./assets/language/en.json";
import my from "./assets/language/my.json";
import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem("langs"),
  messages: {
    en: en,
    my: my,
  },
});
