<template>
  <section>
    <div class="">
      <!-- Content -->
      <div class="flex flex-col h-full justify-center items-center">
        <img
          class="w-full h-auto object-center"
          :src="campaignHeader1[$i18n.locale]"
        />
        <!-- Arrow Bottom -->
        <div class="relative">
          <a href="#stepsToPlay">
            <svg
              class="h-5 lg:h-10 mx-auto -mt-5 lg:-mt-10"
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 316.1 143.58"
            >
              <path
                fill="#fff"
                fill-opacity="1"
                id="Path_5"
                data-name="Path 5"
                class="cls-1"
                d="M0,143.49s27.81, 0,82.78-84.11,129-67.41,164.66, 0,68.66,84.11,68.66,84.11Z"
              />
              <path
                fill="#1c2343"
                fill-opacity="1"
                id="Icon_awesome-arrow-down"
                data-name="Icon awesome-arrow-down"
                class="cls-2 animate-bounce"
                d="M189.36,76.48,191.87,79a2.71,2.71,0,0,1,0,3.83l0,0-22,22a2.71,2.71,0,0,1-3.83,0l0,0-22-22A2.7,2.7,0,0,1,144,79l0,0,2.52-2.52a2.72,2.72,0,0,1,3.84,0l0,0,13,13.63V57.64a2.71,2.71,0,0,1,2.7-2.71h3.64a2.71,2.71,0,0,1,2.71,2.7h0V90.15l13-13.63a2.69,2.69,0,0,1,3.82-.11l.06.06Z"
              />
            </svg>
          </a>
        </div>
        <div class="w-full bg-white h-auto py-5 md:py-10" id="stepsToPlay">
          <h1
            class="
              text-2xl
              md:text-4xl
              font-semibold
              text-center
              md:text-left
              ml-0
              md:ml-10
            "
          >
            {{ $t("steps_to_play") }}
          </h1>
        </div>
        <img
          class="w-full h-auto object-center"
          :src="campaignHeader2[$i18n.locale]"
        />
      </div>
    </div>
  </section>
</template>
<script>
import campaignHeaderAsset from "@/assets/campaign/web_banner.png";
import campaignStepEn from "@/assets/campaign/EN/steps.png";
import campaignStepMy from "@/assets/campaign/my/steps.png";

export default {
  data: function() {
    return {
      lang: "EN",
      campaignHeader1: {
        my: campaignHeaderAsset,
        en: campaignHeaderAsset
      },
      campaignHeader2: {
        my: campaignStepMy,
        en: campaignStepEn
      }
    };
  }
  // created() {
  //   if (localStorage.getItem("langs") == "en") {
  //     this.lang = "EN";
  //     console.log(localStorage.getItem("langs"));
  //     // this.headerImage1 = require(`@/assets/campaign/EN/campaign-header-1.jpg`)
  //     // this.headerImage2 = require(`@/assets/campaign/EN/campaign-header-2.jpg`)
  //   } else {
  //     this.lang = "BM";
  //     // this.headerImage1 = require(`@/assets/campaign/BM/campaign-header-1.jpg`)
  //     // this.headerImage2 = require(`@/assets/campaign/BM/campaign-header-2.jpg`)
  //   }
  //   window.location.reload;
  // }
};
</script>
<style scoped>
section {
  background: rgba(3, 52, 128, 1);
}
</style>
