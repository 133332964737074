<template>
  <div class="w-full h-auto bg-white">
    <div class="flex flex-col px-2 md:px-10">
      <!-- Image Section -->
      <div class="p-4 md:p-10 py-5 md:py-20">
        <div class="grid md:grid-cols-4 gap-4 flex mx-auto">
          <div class="w-full flex justify-center">
            <img
              class="
                w-4/5
                h-full
                object-center
                transition
                duration-500
                ease-in-out
                transform
                hover:-translate-y-1
                hover:scale-110
                rounded-lg
              "
              src="@/assets/ads/homepage-ads.jpg"
            />
          </div>
          <div class="w-full flex justify-center">
            <img
              class="
                w-4/5
                h-full
                object-center
                transition
                duration-500
                ease-in-out
                transform
                hover:-translate-y-1
                hover:scale-110
                rounded-lg
              "
              src="@/assets/ads/news-ads.jpg"
            />
          </div>
          <div class="w-full flex justify-center">
            <img
              class="
                w-4/5
                h-full
                object-center
                transition
                duration-500
                ease-in-out
                transform
                hover:-translate-y-1
                hover:scale-110
                rounded-lg
              "
              src="@/assets/ads/article-ads.jpg"
            />
          </div>
          <div class="w-full flex justify-center">
            <img
              class="
                w-4/5
                h-full
                object-center
                transition
                duration-500
                ease-in-out
                transform
                hover:-translate-y-1
                hover:scale-110
                rounded-lg
              "
              src="@/assets/ads/highlight-ads.jpg"
            />
          </div>
        </div>
      </div>
      <div class="mx-5 py-5 pb-24">
        <div
          class="
            container
            mx-auto
            w-full
            h-auto
            bg-white
            rounded-3xl
            shadow-2xl
          "
        >
          <div class="p-7 flex flex-col">
            <div class="items-center">
              <h1 class="text-center text-4xl text-gray-700 font-bold">
                {{ $t("advertise_content_3_title") }}
              </h1>
            </div>
            <div class="divide-y">
              <!-- Content -->
              <div>
                <div class="grid grid-rows lg:grid-cols-4 gap-4 pt-24 pb-16">
                  <!-- Mobile stats 1 -->
                  <div class="flex flex-col items-center w-full">
                    <div
                      class="
                        rounded-full
                        flex
                        bg-gray-200
                        h-32
                        w-32
                        items-center
                        justify-center
                      "
                    >
                      <div class="flex flex-col">
                        <h1
                          class="
                            text-blue-800 text-center text-3xl
                            font-semibold
                          "
                        >
                          5.11
                        </h1>
                        <h1
                          class="
                            text-blue-800
                            uppercase
                            text-center
                            font-semibold
                          "
                        >
                          {{ $t("billion") }}
                        </h1>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <h1
                        class="
                          text-gray-600 text-xl
                          font-semibold
                          text-center
                          pt-5
                        "
                      >
                        Data Reportal
                      </h1>
                      <h1
                        class="
                          text-gray-600 text-lg
                          font-regular
                          px-2
                          text-center
                          pt-2
                        "
                      >
                        {{ $t("advertise_content_3_description_1") }}
                      </h1>
                    </div>
                  </div>
                  <!-- Mobile stats 2 -->
                  <div class="flex flex-col items-center w-full">
                    <div
                      class="
                        rounded-full
                        flex
                        bg-gray-200
                        h-32
                        w-32
                        items-center
                        justify-center
                      "
                    >
                      <div class="flex flex-col">
                        <h1
                          class="
                            text-blue-800 text-center text-3xl
                            font-semibold
                          "
                        >
                          x7
                        </h1>
                        <h1
                          class="
                            text-blue-800
                            uppercase
                            text-center
                            font-semibold
                          "
                        >
                          {{ $t("data_traffic") }}
                        </h1>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <h1
                        class="
                          text-gray-600 text-xl
                          font-semibold
                          text-center
                          pt-5
                        "
                      >
                        Cisco
                      </h1>
                      <h1
                        class="
                          text-gray-600 text-lg
                          font-regular
                          px-2
                          text-center
                          pt-2
                        "
                      >
                        {{ $t("advertise_content_3_description_2") }}
                      </h1>
                    </div>
                  </div>
                  <!-- Mobile stats 3 -->
                  <div class="flex flex-col items-center w-full">
                    <div
                      class="
                        rounded-full
                        flex
                        bg-gray-200
                        h-32
                        w-32
                        items-center
                        justify-center
                      "
                    >
                      <div class="flex flex-col">
                        <h1
                          class="
                            text-blue-800 text-center text-3xl
                            font-semibold
                          "
                        >
                          80%
                        </h1>
                        <h1
                          class="
                            text-blue-800
                            uppercase
                            text-center
                            font-semibold
                          "
                        >
                          {{ $t("app_user") }}
                        </h1>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <h1
                        class="
                          text-gray-600 text-xl
                          font-semibold
                          text-center
                          pt-5
                        "
                      >
                        SmartInsights
                      </h1>
                      <h1
                        class="
                          text-gray-600 text-lg
                          font-regular
                          px-2
                          text-center
                          pt-2
                        "
                      >
                        {{ $t("advertise_content_3_description_3") }}
                      </h1>
                    </div>
                  </div>
                  <!-- Mobile stats 4 -->
                  <div class="flex flex-col items-center w-full">
                    <div
                      class="
                        rounded-full
                        flex
                        bg-gray-200
                        h-32
                        w-32
                        items-center
                        justify-center
                      "
                    >
                      <div class="flex flex-col">
                        <h1
                          class="
                            text-blue-800 text-center text-3xl
                            font-semibold
                          "
                        >
                          51%
                        </h1>
                        <h1
                          class="
                            text-blue-800
                            uppercase
                            text-center
                            font-semibold
                          "
                        >
                          {{ $t("a&p_budget") }}
                        </h1>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <h1
                        class="
                          text-gray-600 text-xl
                          font-semibold
                          text-center
                          pt-5
                        "
                      >
                        HubSpot
                      </h1>
                      <h1
                        class="
                          text-gray-600 text-lg
                          font-regular
                          px-2
                          text-center
                          pt-2
                        "
                      >
                        {{ $t("advertise_content_3_description_4") }}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Footer -->
              <div class="pt-5">
                <h1 class="text-lg text-center text-gray-600">
                  {{ $t("advertise_content_3_description_5") }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
section {
  background: rgba(3, 52, 128, 1);
}
</style>
