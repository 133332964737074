import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VeeValidate from "vee-validate";
import VueYouTubeEmbed from "vue-youtube-embed";
import VueSimpleAlert from "vue-simple-alert";

import "@dzangolab/vue-accordion/dist/accordion.css";
import ToggleButton from "vue-js-toggle-button";

import "./index.css";
import "swiper/swiper-bundle.css";

import router from "./router";
import i18n from "./i18n";

/**
 * Firebase initialization
 */

import firebase from "firebase/app";
import firebaseConfig from "./firebase/firebase-config";

firebase.default.initializeApp(firebaseConfig);

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.use(VueRouter);
Vue.use(VueAwesomeSwiper);
Vue.use(ToggleButton);
Vue.use(VueYouTubeEmbed);
Vue.use(VueSimpleAlert);

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
