<template>
  <div class="container mx-auto flex flex-row py-16">
    <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
      <div class="">
        <h1 class="text-xl font-bold text-blue-900">
          {{ $t("terms1") }}
        </h1>
        <p class="text-base">
          {{ $t("terms2") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms3") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms4") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms5") }}
        </p>
        <br />
        <p class="text-base">{{ $t("terms6") }}</p>
        <br />
        <p class="text-xl font-bold text-blue-900">
          {{ $t("terms7") }}
        </p>
        <p class="text-base">{{ $t("terms8") }}</p>
        <br />
        <p class="text-xl font-bold text-blue-900">
          {{ $t("terms9") }}
        </p>

        <p class="text-base">{{ $t("terms10") }}</p>
        <br />
        <p class="text-base">{{ $t("terms11") }}</p>
        <br />
        <p class="text-xl font-bold text-blue-900">
          {{ $t("terms12") }}
        </p>
        <p class="text-base">{{ $t("terms13") }}</p>
        <br />
        <p class="text-base">{{ $t("terms14") }}</p>
        <ul class="list-disc ml-5">
          <li>{{ $t("terms15") }}</li>
          <li>{{ $t("terms16") }}</li>
          <li>{{ $t("terms17") }}</li>
          <li>{{ $t("terms18") }}</li>
          <li>{{ $t("terms19") }}</li>
          <li>{{ $t("terms20") }}</li>
          <li>{{ $t("terms21") }}</li>
          <li>{{ $t("terms22") }}</li>
        </ul>
        <br />
        <p class="text-xl font-bold text-blue-900">
          {{ $t("terms23") }}
        </p>

        <p class="text-base">
          {{ $t("terms24") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms25") }}
        </p>

        <p class="text-base">
          {{ $t("terms26") }}
        </p>
        <p class="text-base">
          {{ $t("terms27") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms28") }}
        </p>
        <br />
        <p class="text-xl font-bold text-blue-900">
          {{ $t("terms29") }}
        </p>
        <p class="text-base">
          {{ $t("terms30") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms31") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms32") }}
        </p>
        <br />
        <p class="text-xl font-bold text-blue-900">
          {{ $t("terms33") }}
        </p>
        <p class="text-base">
          {{ $t("terms34") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms35") }}
        </p>
        <br />
        <p class="text-xl font-bold text-blue-900">
          {{ $t("terms36") }}
        </p>
        <p class="text-base">
          {{ $t("terms37") }}
        </p>
        <br />
        <p class="text-xl font-bold text-blue-900">
          {{ $t("terms38") }}
        </p>
        <p class="text-base">
          {{ $t("terms39") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms40") }}
        </p>
        <br />
        <p class="text-xl font-bold text-blue-900">
          {{ $t("terms41") }}
        </p>
        <p class="text-base">
          {{ $t("terms42") }}
        </p>
        <br />
        <p class="text-xl text-blue-900 font-bold">
          {{ $t("terms43") }}
        </p>

        <p class="text-base">
          {{ $t("terms44") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms45") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms46") }}
        </p>
        <br />
        <p class="text-xl text-blue-900 font-bold">
          {{ $t("terms47") }}
        </p>
        <p class="text-base">
          {{ $t("terms48") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms49") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms50") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms51") }}
        </p>
        <br />
        <p class="text-xl text-blue-900 font-bold">
          {{ $t("terms52") }}
        </p>
        <p class="text-base">
          {{ $t("terms53") }}
        </p>
        <br />
        <p class="text-base">
          {{ $t("terms54") }}
        </p>
        <br /><br /><br /><br /><br /><br />
        <small>
          <i>{{ $t("terms55") }}</i></small
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
