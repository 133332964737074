<template>
  <div
    class="bg-fixed bg-contain"
    style="background-image: url('../bg/Bluebackground.png')"
  >
    <Nav></Nav>
    <!-- <CampaignHome class="h-auto bg-hero-blue"></CampaignHome> -->
    <Product class="bg-hero-blue"></Product>
    <Feature id="feature" class="bg-white"></Feature>
    <About class="bg-gray-100"></About>
    <Footer class="bg-hero-blue px-10"></Footer>
    <Copyright></Copyright>
  </div>
</template>

<script>
// import Product from "../components/Product";
import Feature from "../components/Feature";
import About from "../components/About";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
// import Modal from "../components/Modal";
import Copyright from "../components/Copyright";
// import CampaignHome from "../components/CampaignHome";
import Product from "../components/Product";
export default {
  components: {
    // CampaignHome,
    Product,
    Feature,
    About,
    Footer,
    Nav,
    Copyright
    // Modal,
  }
  // mounted() {
  //   this.$refs.modalAnnouncement.openModal();
  // },
};
</script>

<style>
.bg-hero-blue {
  background: rgba(3, 52, 128, 1);
}
</style>
