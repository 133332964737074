<template>
  <div class="campaignTnC">
    <div class="flex flex-col h-16 justify-center items-center">
      <div class="w-full bg-white h-auto py-5 md:py-10" id="stepsToPlay">
        <h1
          class="
            text-2xl
            md:text-4xl
            font-semibold
            text-center
            md:text-left
            ml-0
            md:ml-10
            capitalize
          "
        >
          {{ $t("other-campaign-tnc-title") }}
        </h1>
      </div>
    </div>
    <div class="container mx-auto flex flex-row py-5 px-4 md:px-0">
      <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
        <div class="">
          <h1 class="text-2xl font-bold text-center text-blue-900 pb-5">
            {{ $t("other-campaign-title") }}
          </h1>
          <h1
            class="text-2xl font-bold text-center text-blue-900 pb-5 uppercase"
          >
            {{ $t("other-campaign-tnc-title") }}
          </h1>
          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("other-the-contest") }}
          </h1>
          <p class="text-base pt-2">1. {{ $t("other-the-contest-1") }}</p>
          <br />
          <p class="text-base">2. {{ $t("other-the-contest-2") }}</p>
          <br />

          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("other-contest-eligibility") }}
          </h1>
          <p class="text-base pt-2">
            3. {{ $t("other-contest-eligibility-1") }}
          </p>
          <div class="flex flex-row py-2">
            <p class="px-3">a.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-2") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">b.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-3") }}
            </p>
          </div>
          <br />
          <p class="text-base">4. {{ $t("other-contest-eligibility-4") }}</p>
          <br />
          <p class="text-base">5. {{ $t("other-contest-eligibility-5") }}</p>
          <br />
          <p class="text-base">6. {{ $t("other-contest-eligibility-6-a") }}</p>
          <br />
          <p class="text-base">7. {{ $t("other-contest-eligibility-6") }}</p>
          <br />
          <p class="text-base">8. {{ $t("other-contest-eligibility-7") }}</p>
          <br />
          <p class="text-base">9. {{ $t("other-contest-eligibility-8") }}</p>
          <br />

          <p class="text-base">10. {{ $t("other-contest-eligibility-9") }}</p>
          <br />

          <p class="text-base">11. {{ $t("other-contest-eligibility-10") }}</p>
          <br />

          <p class="text-base">12. {{ $t("other-contest-eligibility-11") }}</p>

          <div class="flex flex-row py-2">
            <p class="px-3">a.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-12") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">b.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-13") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">c.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-14") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">d.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-15") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">e.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-16") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">f.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-17") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">g.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-18") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">h.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-19") }}
            </p>
          </div>
          <br />
          <p class="text-base">13. {{ $t("other-contest-eligibility-20") }}</p>

          <div class="flex flex-row py-2">
            <p class="px-3">a.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-21") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">b.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-22") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">c.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-23") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">d.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-24") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">e.</p>
            <p class="text-base">
              {{ $t("other-contest-eligibility-25") }}
            </p>
          </div>
          <br />

          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("other-organizer-rights") }}
          </h1>
          <p class="text-base pt-2">14. {{ $t("other-organizer-rights-1") }}</p>
          <br />
          <p class="text-base">15. {{ $t("other-organizer-rights-2") }}</p>
          <br />
          <p class="text-base">16. {{ $t("other-organizer-rights-3") }}</p>
          <br />
          <p class="text-base">17. {{ $t("other-organizer-rights-4") }}</p>
          <br />
          <p class="text-base">18. {{ $t("other-organizer-rights-5") }}</p>
          <br />
          <p class="text-base">19. {{ $t("other-organizer-rights-6") }}</p>
          <br />
          <p class="text-base">20. {{ $t("other-organizer-rights-7") }}</p>
          <br />
          <p class="text-base">21. {{ $t("other-organizer-rights-8") }}</p>
          <br />

          <h1 class="text-xl font-bold text-blue-900 pt-2">
            {{ $t("other-privacy-notice") }}
          </h1>
          <br />
          <p class="text-base py-2">
            {{ $t("other-privacy-notice-1") }}
          </p>
          <br />
          <small>
            <i>{{ $t("terms55") }}</i></small
          >
        </div>
      </div>
    </div>
    <div class="container mx-auto flex flex-row py-16 px-4 md:px-0">
      <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
        <div class="">
          <h1 class="text-2xl font-bold text-center text-blue-900 pb-5">
            {{ $t("other-campaign-title") }}
          </h1>
          <h1
            class="text-2xl font-bold text-center text-blue-900 pb-5 uppercase"
          >
            {{ $t("other-campaign-tnc-redemption-title") }}
          </h1>
          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("other-redemption") }}
          </h1>
          <br />
          <p class="text-base">1. {{ $t("other-redemption-1") }}</p>
          <br />
          <p class="text-base">2. {{ $t("other-redemption-2") }}</p>
          <br />
          <p class="text-base">3. {{ $t("other-redemption-3") }}</p>
          <br />
          <p class="text-base">4. {{ $t("other-redemption-4") }}</p>
          <br />
          <p class="text-base">5. {{ $t("other-redemption-5") }}</p>
          <br />
          <p class="text-base">6. {{ $t("other-redemption-6") }}</p>
          <br />
          <p class="text-base">7. {{ $t("other-redemption-7") }}</p>
          <br />
          <p class="text-base">8. {{ $t("other-redemption-8") }}</p>
          <br />
          <p class="text-base">9. {{ $t("other-redemption-9") }}</p>
          <br />
          <p class="text-base">10. {{ $t("other-redemption-10") }}</p>
          <br />
          <p class="text-base">11. {{ $t("other-redemption-11") }}</p>
          <br />

          <small>
            <i>{{ $t("terms55") }}</i></small
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
section {
  background: rgba(3, 52, 128, 1);
}
</style>
