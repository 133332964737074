<template>
  <div class="campaignTnC">
    <div class="flex flex-col h-16 justify-center items-center">
      <div class="w-full bg-white h-auto py-5 md:py-10" id="terms">
        <h1
          class="
            text-2xl
            md:text-4xl
            font-semibold
            text-center
            md:text-left
            ml-0
            md:ml-10
            capitalize
          "
        >
          {{ $t("campaign-tnc-title") }}
        </h1>
      </div>
    </div>
    <div class="container mx-auto flex flex-row py-5 px-4 md:px-0">
      <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
        <div class="">
          <h1 class="text-2xl font-bold text-center text-blue-900 pb-5">
            {{ $t("campaign-title") }}
          </h1>
          <h1
            class="text-2xl font-bold text-center text-blue-900 pb-5 uppercase"
          >
            {{ $t("campaign-tnc-title") }}
          </h1>
          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("the-contest") }}
          </h1>
          <p class="text-base pt-2">1. {{ $t("the-contest-1") }}</p>
          <br />
          <p class="text-base">2. {{ $t("the-contest-2") }}</p>
          <br />

          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("contest-eligibility") }}
          </h1>
          <p class="text-base pt-2">3. {{ $t("contest-eligibility-1") }}</p>
          <div class="flex flex-row py-2">
            <p class="px-6">a.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-2") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">b.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-3") }}
            </p>
          </div>
          <br />
          <p class="text-base">4. {{ $t("contest-eligibility-4") }}</p>
          <br />
          <p class="text-base">5. {{ $t("contest-eligibility-5") }}</p>
          <br />
          <p class="text-base">6. {{ $t("contest-eligibility-6") }}</p>
          <br />
          <p class="text-base">7. {{ $t("contest-eligibility-7") }}</p>
          <div class="flex flex-row py-2">
            <p class="px-6">a.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-7-a") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="pl-16 pr-6">i.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-7-a-i") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="pl-16 pr-6">ii.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-7-a-ii") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="pl-16 pr-6">iii.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-7-a-iii") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">b.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-7-b") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="pl-16 pr-6">i.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-7-b-i") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="pl-16 pr-6">ii.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-7-b-ii") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="pl-16 pr-6">iii.</p>
            <p class="text-base">
              {{ $t("contest-eligibility-7-b-iii") }}
            </p>
          </div>
          <br />
          <p class="text-base">8. {{ $t("contest-eligibility-8") }}</p>
          <br />
          <p class="text-base">9. {{ $t("contest-eligibility-9") }}</p>
          <br />

          <p class="text-base">10. {{ $t("contest-eligibility-10") }}</p>
          <br />

          <p class="text-base">11. {{ $t("contest-eligibility-11") }}</p>
          <br />

          <p class="text-base">12. {{ $t("contest-eligibility-12") }}</p>
          <br />

          <p class="text-base">13. {{ $t("contest-eligibility-13") }}</p>
          <br />
          <p class="text-base">14. {{ $t("contest-eligibility-14") }}</p>
          <br />
          <p class="text-base">15. {{ $t("contest-eligibility-15") }}</p>
          <br />

          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("winners-selection") }}
          </h1>
          <p class="text-base pt-2 pb-4">16. {{ $t("winners-selection-1") }}</p>
          <table class="table-fixed border-2 border-blue-900 items-center">
            <thead class="table-fixed border-2 border-blue-900">
              <tr>
                <th class="w-1/12 border-2 border-blue-900 p-2">No.</th>
                <th class="w-4/6 border-2 border-blue-900 p-2">
                  {{ $t("winners-selection-1-t-h-1") }}
                </th>
                <th class="w-1/6 border-2 border-blue-900 p-2">
                  {{ $t("winners-selection-1-t-h-2") }}
                </th>
              </tr>
            </thead>
            <tbody class="table-fixed border-2 border-blue-900">
              <tr>
                <td class="text-center border-2 border-blue-900">1</td>
                <td class="text-center border-2 border-blue-900 p-2">
                  {{ $t("winners-selection-1-c-1") }}
                </td>
                <td class="text-center border-2 border-blue-900">1</td>
              </tr>
              <tr>
                <td class="text-center border-2 border-blue-900">2</td>
                <td class="text-center border-2 border-blue-900 p-2">
                  {{ $t("winners-selection-1-c-2") }}
                </td>
                <td class="text-center border-2 border-blue-900">1</td>
              </tr>
              <tr>
                <td class="text-center border-2 border-blue-900">3</td>
                <td class="text-center border-2 border-blue-900 p-2">
                  {{ $t("winners-selection-1-c-3") }}
                </td>
                <td class="text-center border-2 border-blue-900">3</td>
              </tr>
            </tbody>
          </table>
          <br />
          <p class="text-base">17. {{ $t("winners-selection-2") }}</p>
          <br />
          <p class="text-base">18. {{ $t("winners-selection-3") }}</p>
          <div class="flex flex-row py-2">
            <p class="px-6">a.</p>
            <p class="text-base">
              {{ $t("winners-selection-3-a") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">b.</p>
            <p class="text-base">
              {{ $t("winners-selection-3-b") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">c.</p>
            <p class="text-base">
              {{ $t("winners-selection-3-c") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">d.</p>
            <p class="text-base">
              {{ $t("winners-selection-3-d") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">e.</p>
            <p class="text-base">
              {{ $t("winners-selection-3-e") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">f.</p>
            <p class="text-base">
              {{ $t("winners-selection-3-f") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">g.</p>
            <p class="text-base">
              {{ $t("winners-selection-3-g") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">h.</p>
            <p class="text-base">
              {{ $t("winners-selection-3-h") }}
            </p>
          </div>
          <br />
          <p class="text-base">19. {{ $t("winners-selection-4") }}</p>
          <div class="flex flex-row py-2">
            <p class="px-6">a.</p>
            <p class="text-base">
              {{ $t("winners-selection-4-a") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-6">b.</p>
            <p class="text-base">
              {{ $t("winners-selection-4-b") }}
            </p>
          </div>
          <br />
          <div class="flex flex-row py-2">
            <p class="px-6">c.</p>
            <p class="text-base">
              {{ $t("winners-selection-4-c") }}
            </p>
          </div>
          <br />
          <div class="flex flex-row py-2">
            <p class="px-6">d.</p>
            <p class="text-base">
              {{ $t("winners-selection-4-d") }}
            </p>
          </div>
          <br />
          <div class="flex flex-row py-2">
            <p class="px-6">e.</p>
            <p class="text-base">
              {{ $t("winners-selection-4-e") }}
            </p>
          </div>
          <br />

          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("organizer-rights") }}
          </h1>
          <p class="text-base pt-2">20. {{ $t("organizer-rights-1") }}</p>
          <br />
          <p class="text-base">21. {{ $t("organizer-rights-2") }}</p>
          <br />
          <p class="text-base">22. {{ $t("organizer-rights-3") }}</p>
          <br />
          <p class="text-base">23. {{ $t("organizer-rights-4") }}</p>
          <br />
          <p class="text-base">24. {{ $t("organizer-rights-5") }}</p>
          <br />
          <p class="text-base">25. {{ $t("organizer-rights-6") }}</p>
          <br />
          <p class="text-base">26. {{ $t("organizer-rights-7") }}</p>
          <br />
          <p class="text-base">27. {{ $t("organizer-rights-8") }}</p>
          <br />

          <h1 class="text-xl font-bold text-blue-900 pt-2">
            {{ $t("privacy-notice") }}
          </h1>
          <br />
          <p class="text-base py-2">
            {{ $t("privacy-notice-1") }}
          </p>
          <br />
          <!-- <small>
            <i>{{ $t("terms55") }}</i></small
          > -->
        </div>
      </div>
    </div>
    <div class="container mx-auto flex flex-row py-16 px-4 md:px-0">
      <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
        <div class="">
          <h1 class="text-2xl font-bold text-center text-blue-900 pb-5">
            {{ $t("campaign-title") }}
          </h1>
          <h1
            class="text-2xl font-bold text-center text-blue-900 pb-5 uppercase"
          >
            {{ $t("campaign-tnc-redemption-title") }}
          </h1>
          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("redemption") }}
          </h1>
          <br />
          <p class="text-base">1. {{ $t("redemption-1") }}</p>
          <br />
          <p class="text-base">2. {{ $t("redemption-2") }}</p>
          <br />
          <p class="text-base">3. {{ $t("redemption-3") }}</p>
          <br />
          <p class="text-base">4. {{ $t("redemption-4") }}</p>
          <br />
          <p class="text-base">5. {{ $t("redemption-5") }}</p>
          <br />
          <p class="text-base">6. {{ $t("redemption-6") }}</p>
          <br />
          <p class="text-base">7. {{ $t("redemption-7") }}</p>
          <br />
          <p class="text-base">8. {{ $t("redemption-8") }}</p>
          <br />
          <p class="text-base">9. {{ $t("redemption-9") }}</p>
          <br />
          <p class="text-base">10. {{ $t("redemption-10") }}</p>
          <br />
          <p class="text-base">11. {{ $t("redemption-11") }}</p>
          <br />

          <!-- <small>
            <i>{{ $t("terms55") }}</i></small
          > -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
section {
  background: rgba(3, 52, 128, 1);
}
</style>
