<template>
  <section
    id="about"
    class="bg-white relative px-10 py-14"
  >
    <div class="text-left">
      <h2 class="text-4xl leading-tight font-semibold">
        {{ $t("about_us") }}
      </h2>
    </div>

    <div class="items-center flex justify-center">
      <div class="sm:w-full md:w-3/4 lg:w-1/2 mt-8 pt-14 md:mt-0 ">
        <img
          class="mx-42 items-center"
          src="@/assets/logo/Ingres-x-mfl.png"
          alt=""
        />
      </div>
    </div>

    <div
      class="text-center mt-12 md:mt-20 w-full text-xl mx-auto items-center"
    >
      <h1>
        {{ $t("aboutus_para1") }}
      </h1>
      <br />
      <h1>
        {{ $t("aboutus_para2") }}
      </h1>
      <br />
      <h1>
        {{ $t("aboutus_para3") }}
      </h1>
      <br />
      <h1>
        {{ $t("aboutus_para4") }}
      </h1>
    </div>
  </section>
</template>

<script>
export default {
    
}
</script>