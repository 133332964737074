<template>
  <div class="bg-hero-blue h-auto text-white mx-auto mb-5">
    <div class="container mx-auto flex flex-row py-16">
      <!-- Icon -->
      <div class="py-10">
        <svg
        class="h-10 w-10"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 372.37 488.9"
          fill="white"
        >
          <title>Shoot!-UI</title>
          <g id="DHefMQ.tif">
            <path
              class="cls-1"
              d="M.67,70.56C71.15,69.37,133,46.28,186.38,0c5.54,4.53,11,9.35,16.8,13.7C243.53,44,288.72,62.33,338.84,68.23c9.56,1.13,19.21,1.59,28.83,1.88,3.85.12,4.71,1.44,4.7,5-.24,78.35-.17,156.71-.69,235.07-.15,23.54-10.49,43.65-24.65,61.9-15.53,20-34.38,36.56-54.47,51.74-32.46,24.5-67.39,45.08-103.1,64.38a7.17,7.17,0,0,1-5.82.17C141.06,465.31,99.2,441.07,61.7,410.15c-19.85-16.36-38.11-34.26-50.31-57.4A96.07,96.07,0,0,1,0,307.27Q.06,191.73.32,76.19C.33,74.41.54,72.63.67,70.56ZM184.85,375.49v0c19.46,0,38.92.1,58.38,0,19.19-.12,32.18-13.22,32.22-32.4q.09-53.65,0-107.3c0-19.54-12.89-32.63-32.45-32.71q-58.12-.22-116.25,0c-19.34.08-32.37,13.53-32.38,32.91q0,52.41,0,104.8c0,22.32,12.59,34.73,35.06,34.75ZM148.29,181.65c0-10.26,0-20.36,0-30.46,0-18.86,14.09-33.8,33.93-35.83,17.95-1.84,36.35,11.41,38.87,29.29,1.44,10.29.66,20.9.86,31.37,0,1.9,0,3.81,0,5.64h20.8c0-9.85-.41-19.34.08-28.77,2-39.56-41.22-71.87-81.91-53.1-21.55,9.95-33.47,27.2-33.64,51.58-.08,10.08,0,20.17,0,30.68,6.1,0,11.55,0,17,0C145.4,182,146.52,181.83,148.29,181.65Z"
            />
            <path
              class="cls-1"
              d="M179.63,306.78a42,42,0,0,1,0-4.49c.67-5.73-.42-9.9-6.1-13.45-7.17-4.48-9-14.47-5.86-22.38a20.16,20.16,0,0,1,19.67-12.55c8.64.44,15.76,6.4,18.19,15.22,2.16,7.82-1.05,16.93-8.42,21.15-3.87,2.23-4.7,4.82-4.54,8.78.24,6.31.07,12.63,0,18.95,0,4.18-1.77,7.13-6.3,7.42-3.8.24-6.53-3-6.67-7.68C179.54,314.09,179.63,310.43,179.63,306.78Z"
            />
          </g>
        </svg>
      </div>
      <!-- Title & Descriptions -->
      <div class="flex flex-col">
        <h1 class="pt-8 pl-3 text-4xl md:text-6xl font-bold">
          {{ $t("policy_title") }}
        </h1>
        <h1 class="pt-2 pl-3 text-lg font-regular">
          {{ $t("privacy_mini") }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
