<template>
  <div class="campaignTnC">
    <div class="flex flex-col h-16 justify-center items-center">
      <div class="w-full bg-white h-auto py-5 md:py-10" id="stepsToPlay">
        <h1
          class="
            text-2xl
            md:text-4xl
            font-semibold
            text-center
            md:text-left
            ml-0
            md:ml-10
            capitalize
          "
        >
          {{ $t("jts-campaign-tnc-title") }}
        </h1>
      </div>
    </div>
    <div class="container mx-auto flex flex-row py-5 px-4 md:px-0">
      <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
        <div class="">
          <h1 class="text-2xl font-bold text-center text-blue-900 pb-5">
            {{ $t("jts-campaign-title") }}
          </h1>
          <h1
            class="text-2xl font-bold text-center text-blue-900 pb-5 uppercase"
          >
            {{ $t("jts-campaign-tnc-title") }}
          </h1>
          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("jts-the-contest") }}
          </h1>
          <p class="text-base pt-2">1. {{ $t("jts-the-contest-1") }}</p>
          <br />
          <p class="text-base">2. {{ $t("jts-the-contest-2") }}</p>
          <br />

          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("jts-contest-eligibility") }}
          </h1>
          <p class="text-base pt-2">3. {{ $t("jts-contest-eligibility-1") }}</p>
          <div class="flex flex-row py-2">
            <p class="px-3">a.</p>
            <p class="text-base">
              {{ $t("jts-contest-eligibility-2") }}
            </p>
          </div>
          <div class="flex flex-row py-2">
            <p class="px-3">b.</p>
            <p class="text-base">
              {{ $t("jts-contest-eligibility-3") }}
            </p>
          </div>
          <br />
          <p class="text-base">4. {{ $t("jts-contest-eligibility-4") }}</p>
          <br />
          <p class="text-base">5. {{ $t("jts-contest-eligibility-5") }}</p>
          <br />
          <p class="text-base">6. {{ $t("jts-contest-eligibility-6") }}</p>
          <br />
          <p class="text-base">7. {{ $t("jts-contest-eligibility-7") }}</p>
          <br />
          <p class="text-base">8. {{ $t("jts-contest-eligibility-8") }}</p>
          <br />

          <table
            class="table-fixed border-2 border-blue-900 items-center mb-10"
          >
            <thead class="table-fixed border-2 border-blue-900">
              <tr>
                <th class="w-2/6 border-2 border-blue-900 p-2">
                  {{ $t("jts-contest-eligibility-8-header") }}
                </th>
                <th class="w-4/6 border-2 border-blue-900 p-2">
                  {{ $t("jts-contest-eligibility-8-header2") }}
                </th>
              </tr>
            </thead>
            <tbody class="table-fixed border-2 border-blue-900">
              <tr>
                <td class="text-center border-2 border-blue-900">
                  {{ $t("jts-contest-eligibility-8-c1") }}
                </td>
                <td class="text-left border-2 border-blue-900 p-2">
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r1") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r2")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r3") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r4")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r5") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r6")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r7") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r8")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r9") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r10")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r11") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r12")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r13") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r14")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r15") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r16")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r17") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r18")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r19") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r20")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r21") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r22")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r23") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r24")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r25") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r26")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r27") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r28")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r29") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r30")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r31") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r32")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r33") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r34")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r35") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r36")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r37") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r38")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r39") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r40")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r41") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r42")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r43") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r44")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r45") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r46")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r47") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r48")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r49") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r50")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r51") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r52")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r53") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r54")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r55") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r56")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r57") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r58")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r59") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r60")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r61") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r62")
                    }}
                    {{
                      $t("jts-contest-eligibility-8-r63")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r64") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r65")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r66") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r67")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r68") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r69")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r70") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r71")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r72") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r73")
                    }}
                  </tr>
                </td>
              </tr>
              <tr>
                <td class="text-center border-2 border-blue-900">
                  {{ $t("jts-contest-eligibility-8-c2") }}
                </td>
                <td class="text-left border-2 border-blue-900 p-2">
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r74") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r75")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r76") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r77")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r78") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r79")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r80") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r81")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r82") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r83")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r84") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r85")
                    }}
                  </tr>
                </td>
              </tr>
              <tr>
                <td class="text-center border-2 border-blue-900">
                  {{ $t("jts-contest-eligibility-8-c3") }}
                </td>
                <td class="text-left border-2 border-blue-900 p-2">
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r86") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r87")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r88") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r89")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r90") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r91")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r92") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r93")
                    }}
                  </tr>
                </td>
              </tr>
              <tr>
                <td class="text-center border-2 border-blue-900">
                  {{ $t("jts-contest-eligibility-8-c4") }}
                </td>
                <td class="text-left border-2 border-blue-900 p-2">
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r94") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r95")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r96") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r97")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r98") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r99")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r100") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r101")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r102") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r103")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r100") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r101")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r102") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r103")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r104") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r105")
                    }}
                  </tr>
                  <br />
                  <tr>
                    <strong>{{ $t("jts-contest-eligibility-8-r106") }}</strong>
                    <br />
                    {{
                      $t("jts-contest-eligibility-8-r107")
                    }}
                  </tr>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="text-base">9. {{ $t("jts-contest-eligibility-9") }}</p>
          <br />

          <p class="text-base">10. {{ $t("jts-contest-eligibility-10") }}</p>
          <br />

          <p class="text-base">11. {{ $t("jts-contest-eligibility-11") }}</p>

          <br />
          <p class="text-base">12. {{ $t("jts-contest-eligibility-12") }}</p>
          <br />

          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("jts-contest-eligibility-winners") }}
          </h1>
          <p class="text-base pt-2">
            13. {{ $t("jts-contest-eligibility-13") }}
          </p>
          <br />
          <p class="text-base">14. {{ $t("jts-contest-eligibility-14") }}</p>
          <br />
          <p class="text-base">15. {{ $t("jts-contest-eligibility-15") }}</p>
          <br />
          <p class="text-base">16. {{ $t("jts-contest-eligibility-16") }}</p>
          <br />
          <p class="text-base">17. {{ $t("jts-contest-eligibility-17") }}</p>
          <br />
          <p class="text-base">18. {{ $t("jts-contest-eligibility-18") }}</p>
          <br />
          <p class="text-base">19. {{ $t("jts-contest-eligibility-19") }}</p>
          <br />
          <p class="text-base">20. {{ $t("jts-contest-eligibility-20") }}</p>
          <br />
          <p class="text-base">21. {{ $t("jts-contest-eligibility-21") }}</p>
          <br />
          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("jts-organizer-rights") }}
          </h1>
          <p class="text-base pt-2">22. {{ $t("jts-organizer-rights-1") }}</p>
          <br />
          <p class="text-base">23. {{ $t("jts-organizer-rights-2") }}</p>
          <br />
          <p class="text-base">24. {{ $t("jts-organizer-rights-3") }}</p>
          <br />
          <p class="text-base">25. {{ $t("jts-organizer-rights-4") }}</p>
          <br />
          <p class="text-base">26. {{ $t("jts-organizer-rights-5") }}</p>
          <br />
          <p class="text-base">27. {{ $t("jts-organizer-rights-6") }}</p>
          <br />
          <p class="text-base">28. {{ $t("jts-organizer-rights-7") }}</p>
          <br />
          <p class="text-base">29. {{ $t("jts-organizer-rights-8") }}</p>
          <br />

          <h1 class="text-xl font-bold text-blue-900 pt-2">
            {{ $t("jts-privacy-notice") }}
          </h1>
          <br />
          <p class="text-base py-2">
            {{ $t("jts-privacy-notice-1") }}
          </p>
          <br />
          <!-- <small>
            <i>{{ $t("terms55") }}</i></small
          > -->
        </div>
      </div>
    </div>
    <div class="container mx-auto flex flex-row py-16 px-4 md:px-0">
      <div class="border-2 rounded-lg bg-white shadow-lg border-blue-900 p-6">
        <div class="">
          <h1 class="text-2xl font-bold text-center text-blue-900 pb-5">
            {{ $t("jts-campaign-title") }}
          </h1>
          <h1
            class="text-2xl font-bold text-center text-blue-900 pb-5 uppercase"
          >
            {{ $t("jts-campaign-tnc-redemption-title") }}
          </h1>
          <h1 class="text-xl font-bold text-blue-900">
            {{ $t("jts-redemption") }}
          </h1>
          <br />
          <p class="text-base">1. {{ $t("jts-redemption-1") }}</p>
          <br />
          <p class="text-base">2. {{ $t("jts-redemption-2") }}</p>
          <br />
          <p class="text-base">3. {{ $t("jts-redemption-3") }}</p>
          <br />
          <p class="text-base">4. {{ $t("jts-redemption-4") }}</p>
          <br />
          <p class="text-base">5. {{ $t("jts-redemption-5") }}</p>
          <br />
          <p class="text-base">6. {{ $t("jts-redemption-6") }}</p>
          <br />
          <p class="text-base">7. {{ $t("jts-redemption-7") }}</p>
          <br />
          <p class="text-base">8. {{ $t("jts-redemption-8") }}</p>
          <br />
          <p class="text-base">9. {{ $t("jts-redemption-9") }}</p>
          <br />
          <p class="text-base">10. {{ $t("jts-redemption-10") }}</p>
          <br />
          <p class="text-base">11. {{ $t("jts-redemption-11") }}</p>
          <br />
          <p class="text-base">12. {{ $t("jts-redemption-12") }}</p>
          <br />
          <p class="text-base">13. {{ $t("jts-redemption-13") }}</p>
          <br />

          <!-- <small>
            <i>{{ $t("terms55") }}</i></small
          > -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
section {
  background: rgba(3, 52, 128, 1);
}
</style>
