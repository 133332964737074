<template>
  <div
    class="bg-fixed bg-contain bg-hero-blue"
    style="background-image: url('/bg/Whitebackground.png"
  >
    <Nav></Nav>
    <CampaignHeader class="h-auto bg-hero-blue"></CampaignHeader>
    <CampaignTnc class="bg-white"></CampaignTnc>
    <Footer class="bg-hero-blue px-5"></Footer>
    <Copyright></Copyright>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Copyright from "../components/Copyright";
import CampaignHeader from "../components/CampaignJTSHeader";
import CampaignTnc from "../components/CampaignJTSTnC";
export default {
  components: {
    CampaignHeader,
    CampaignTnc,
    Footer,
    Nav,
    Copyright
  }
};
</script>

<style>
.bg-hero-blue {
  background: rgba(0, 32, 180, 1);
}
</style>
