<template>
  <div class="bg-blue-50">
    <Nav class="relative"></Nav>
    <Helpdesk class="bg-fixed bg-no-repeat bg-cover"></Helpdesk>
    <Footer class="bg-hero-blue px-5"></Footer>
    <Copyright></Copyright>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import Helpdesk from "../components/Helpdesk.vue";
import Footer from "../components/Footer.vue";
import Copyright from "../components/Copyright.vue";

export default {
  components: {
    Nav,
    Helpdesk,
    Footer,
    Copyright
  }
};
</script>

<style>
.bg-hero-blue {
  background: rgba(3, 52, 128, 1);
}
</style>