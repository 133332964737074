<template>
  <div
    class="bg-fixed bg-contain"
    style="background-image: url('../bg/Bluebackground.png')"
  >
    <Nav></Nav>
    <AdvertiseHeader class="bg-hero-blue"></AdvertiseHeader>
    <AdvertiseContent1 class="bg-white"></AdvertiseContent1>
    <AdvertiseContent2 class="bg-white"></AdvertiseContent2>
    <AdvertiseContact class="bg-white"></AdvertiseContact>
    <Footer class="bg-hero-blue px-5"></Footer>
    <Copyright></Copyright>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Copyright from "../components/Copyright";
import AdvertiseHeader from "../components/AdvertisementHeader";
import AdvertiseContent1 from "../components/AdvertisementContent_1";
import AdvertiseContent2 from "../components/AdvertisementContent_2";
import AdvertiseContact from "../components/AdvertisementContact";
export default {
  components: {
    AdvertiseHeader,
    AdvertiseContent1,
    AdvertiseContent2,
    AdvertiseContact,
    Footer,
    Nav,
    Copyright,
  },
};
</script>

<style>
.bg-hero-blue {
  background: rgba(3, 52, 128, 1);
}
</style>
