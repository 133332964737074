<template>
  <div class="bg-no-repeat bg-contain">
    <Nav class=""></Nav>
    <Help class="bg-hero-blue"></Help>
    <Contact
      class="bg-local bg-no-repeat bg-cover"
      style="background-image: url('/bg/form.png')"
    ></Contact>
    <Footer class="bg-hero-blue px-5"></Footer>
    <Copyright></Copyright>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import Help from "../components/Help.vue";
import Contact from "../components/Contact.vue";
import Footer from "../components/Footer.vue";
import Copyright from "../components/Copyright.vue";

export default {
  components: {
    Nav,
    Help,
    Contact,
    Footer,
    Copyright
  }
};
</script>
<style>
.bg-hero-blue {
  background: rgba(3, 52, 128, 1);
}
</style>
