<template>
  <div
    class="bg-scroll bg-no-repeat bg-contain"
    style="background-image: url('/bg/Whitebackground.png')"
  >
    <Nav></Nav>
    <Pp class="px-5"></Pp>
    <PolicyContent class="px-5"></PolicyContent>
    <Footer class="bg-hero-blue px-5"></Footer>
    <Copyright></Copyright>
  </div>
</template>

<script>

import Nav from '../components/Nav.vue';
import Footer from '../components/Footer.vue';
import Copyright from '../components/Copyright.vue';
import Pp from '../components/Pp.vue';
import PolicyContent from '../components/PolicyContent.vue';
export default {
    components: {
        Nav,
        Footer,
        Copyright,
        Pp,
        PolicyContent
    }

}
</script>

<style>
</style>