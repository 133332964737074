<template>
  <section class="py-16">
    <div>
      <div class="pl-12 px-10">
        <!-- Title Header -->
        <h1 class="text-4xl text-black text-left font-semibold">
          {{ $t("features") }}
        </h1>
      </div>
      <!-- Content Section -->
      <div class="max-w-screen-2xl mx-auto px-4 sm:px-6">
        <div
          class="
            flex flex-col
            items-start
            w-full
            h-auto
            md:flex-row
            sm:flex-row
            mt-12
            items-center
            justify-center
            wrapper
          "
        >
          <carousel
            :autoplay="true"
            :centermode="true"
            :mouse-drag="true"
            :autoplayHoverPause="true"
            :loop="true"
            :per-page="5"
            :per-page-custom="[
              [0, 1],
              [375, 1],
              [560, 3],
              [768, 5],
            ]"
          >
            <slide>
              <div
                @
                class="
                  duration-500
                  ease-in-out
                  transform
                  hover:-translate-y-1
                  hover:scale-110
                  rounded-md
                  flex
                  justify-center
                  p-3
                "
              >
                <img
                  class="w-4/5 h-2/5 object-center rounded-lg"
                  src="@/assets/screens/1.png"
                />
              </div>
            </slide>
            <slide>
              <div
                class="
                  duration-500
                  ease-in-out
                  transform
                  hover:-translate-y-1
                  hover:scale-110
                  rounded-md
                  flex
                  justify-center
                  p-3
                "
              >
                <img
                  class="w-4/5 h-2/5 object-center rounded-lg"
                  src="@/assets/screens/2.png"
                />
              </div>
            </slide>
            <slide>
              <div
                class="
                  duration-500
                  ease-in-out
                  transform
                  hover:-translate-y-1
                  hover:scale-110
                  rounded-md
                  flex
                  justify-center
                  p-3
                "
              >
                <img
                  class="w-4/5 h-2/5 object-center rounded-lg"
                  src="@/assets/screens/3.png"
                />
              </div>
            </slide>
            <slide>
              <div
                class="
                  duration-500
                  ease-in-out
                  transform
                  hover:-translate-y-1
                  hover:scale-110
                  rounded-md
                  flex
                  justify-center
                  p-3
                "
              >
                <img
                  class="w-4/5 h-2/5 object-center rounded-lg"
                  src="@/assets/screens/4.png"
                />
              </div>
            </slide>
            <slide>
              <div
                class="
                  duration-500
                  ease-in-out
                  transform
                  hover:-translate-y-1
                  hover:scale-110
                  rounded-md
                  flex
                  justify-center
                  p-3
                "
              >
                <img
                  class="w-4/5 h-2/5 object-center rounded-lg"
                  src="@/assets/screens/5.png"
                />
              </div>
            </slide>
            <slide>
              <div
                class="
                  duration-500
                  ease-in-out
                  transform
                  hover:-translate-y-1
                  hover:scale-110
                  rounded-md
                  flex
                  justify-center
                  p-3
                "
              >
                <img
                  class="w-4/5 h-2/5 object-center rounded-lg"
                  src="@/assets/screens/6.png"
                />
              </div>
            </slide>
            <slide>
              <div
                class="
                  duration-500
                  ease-in-out
                  transform
                  hover:-translate-y-1
                  hover:scale-110
                  rounded-md
                  flex
                  justify-center
                  p-3
                "
              >
                <img
                  class="w-4/5 h-2/5 object-center rounded-lg"
                  src="@/assets/screens/7.png"
                />
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getIdFromURL } from "vue-youtube-embed";

import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide
  },
  methods: {},
  data: function() {
    return {
      videoId: getIdFromURL(
        "https://www.youtube-nocookie.com/embed/Eb2ht80jkAE"
      )
    };
  }
};
</script>

<style></style>
