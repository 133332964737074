<template>
  <div id="helpdesk">
    <!-- Loading Spinner -->
    <div
      v-if="loading"
      class="
        fixed
        top-0
        left-0
        right-0
        bottom-0
        w-full
        h-screen
        z-50
        overflow-hidden
        bg-gray-700
        opacity-75
        flex flex-col
        items-center
        justify-center
      "
    >
      <div
        class="
          loader
          ease-linear
          rounded-full
          border-4 border-t-4 border-gray-200
          h-12
          w-12
          mb-4
        "
      ></div>
      <h2 class="text-center text-white text-xl font-semibold">
        {{ $t("sending_ticket") }}
      </h2>
      <p class="w-1/3 text-center text-white">
        {{ $t("sending_message") }}
      </p>
    </div>
    <!-- Banner (blue) -->
    <div class="bg-hero-blue h-auto text-white mx-auto mb-5">
      <div class="container mx-auto flex flex-row py-16">
        <!-- Icon -->
        <div class="py-8 ml-8">
          <img
            src="@/assets/img/helpdesk-white.png"
            alt=""
            class="mx-auto h-16"
          />
        </div>
        <!-- Title & Descriptions -->
        <div class="flex flex-col">
          <h1 class="pt-8 pl-3 text-4xl md:text-6xl font-bold">
            {{ $t("helpdesk") }}
          </h1>
          <p class="pt-2 pl-3">{{ $t("helpdesk_content") }}</p>
        </div>
      </div>
    </div>

    <!-- Contact Form -->
    <div class="w-full h-auto bg-blue-50 flex flex-col px-10 md:px-24">
      <!-- Title -->
      <div class="pt-8 pb-8 w-full">
        <h1 class="items-center text-2xl md:text-3xl lg:text-3xl font-semibold">
          {{ $t("submit_ticket") }}
        </h1>
        <p class="pt-8 text-xl font-semibold">{{ $t("ticket_info") }}</p>
      </div>
      <!-- Forms -->
      <div class="">
        <div class="w-full">
          <!-- Contact Name &  Email -->
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block tracking-wide text-lg font-bold mb-2"
                for="grid-first-name"
              >
                {{ $t("contact_name") }}<span class="text-red-600">*</span>
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  border-2 border-gray-300
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                "
                id="grid-first-name"
                type="text"
                placeholder="Jane Doe"
                v-model="fullName"
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="fullName"
              />
              <span
                class="text-red-400 text-sm"
                v-show="errors.has('fullName')"
                >{{ errors.first("fullName") }}</span
              >
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label
                class="block tracking-wide text-lg font-bold mb-2"
                for="grid-last-name"
              >
                {{ $t("email") }}<span class="text-red-600">*</span>
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  border-2 border-gray-300
                  rounded
                  py-3
                  px-4
                  leading-tight
                "
                id="grid-last-name"
                type="text"
                placeholder="mail@mail.com"
                v-model="email"
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="email"
              />
              <span class="text-red-400 text-sm" v-show="errors.has('email')">{{
                errors.first("email")
              }}</span>
            </div>
          </div>
          <!-- Subject -->
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block tracking-wide text-lg text-black font-bold mb-2"
                for="grid-subject"
              >
                {{ $t("subject") }}<span class="text-red-600">*</span>
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  border-2 border-gray-300
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                "
                id="grid-subject"
                type="text"
                placeholder="Example Title"
                v-model="subject"
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="subject"
              />
              <span
                class="text-red-400 text-sm"
                v-show="errors.has('subject')"
                >{{ errors.first("subject") }}</span
              >
            </div>
          </div>
          <!-- Text Area-->
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block tracking-wide text-lg text-black font-bold mb-2"
                for="grid-password"
              >
                {{ $t("ticket_description")
                }}<span class="text-red-600">*</span>
              </label>
              <textarea
                class="
                  appearance-none
                  block
                  h-36
                  w-full
                  border-2 border-gray-300
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                "
                id="grid-password"
                type="text"
                placeholder="Write here"
                v-model="ticket_description"
                name="ticket_description"
                v-validate="'required'"
                data-vv-validate-on="blur"
              />
              <span
                class="text-red-400 text-sm"
                v-show="errors.has('ticket_description')"
                >{{ errors.first("ticket_description") }}</span
              >
            </div>
          </div>

          <!-- Title & Descriptions -->
          <div class="flex flex-col">
            <p class="pb-6 text-xl uppercase font-semibold">
              {{ $t("additional_information") }}
            </p>
          </div>
          <!-- Priority & Classification -->
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block tracking-wide text-lg font-bold mb-2"
                for="grid-state"
              >
                {{ $t("priority") }}<span class="text-red-600">*</span>
              </label>
              <div class="relative">
                <select
                  v-model="priority"
                  name="priority"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  class="
                    block
                    appearance-none
                    w-full
                    border-2 border-gray-300
                    py-3
                    px-4
                    pr-8
                    rounded
                    leading-tight
                  "
                  id="grid-state"
                >
                  <option value="Low">{{ $t("low") }}</option>
                  <option value="Medium">{{ $t("medium") }}</option>
                  <option value="High">{{ $t("high") }}</option>
                </select>
                <span
                  class="text-red-400 text-sm"
                  v-show="errors.has('priority')"
                  >{{ errors.first("priority") }}</span
                >
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block tracking-wide text-lg font-bold mb-2"
                for="grid-state"
              >
                {{ $t("classification") }}<span class="text-red-600">*</span>
              </label>
              <div class="relative">
                <select
                  v-model="classification"
                  name="classification"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  class="
                    block
                    appearance-none
                    w-full
                    border-2 border-gray-300
                    py-3
                    px-4
                    pr-8
                    rounded
                    leading-tight
                  "
                  id="grid-state"
                >
                  <option value="Problem">{{ $t("problem") }}</option>
                  <option value="Request">{{ $t("request") }}</option>
                  <option value="Question">{{ $t("question") }}</option>
                  <option value="Others">{{ $t("others") }}</option>
                </select>
                <span
                  class="text-red-400 text-sm"
                  v-show="errors.has('classification')"
                  >{{ errors.first("classification") }}</span
                >
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <!-- Attachment Upload -->
          <div
            class="
              border-4 border-dashed
              rounded
              border-gray-500
              relative
              mb-10
              pb-10
            "
          >
            <input
              name="attachment"
              type="file"
              accept="image/png, image/jpeg"
              ref="file"
              @change="onFileChange"
              class="
                cursor-pointer
                relative
                block
                opacity-0
                w-full
                h-full
                p-20
                z-50
              "
            />
            <div class="text-center p-10 absolute top-0 right-0 left-0 m-auto">
              <img
                src="@/assets/img/jpg-upload.png"
                alt=""
                class="mx-auto mb-3 h-24"
              />
              <p class="italic text-gray-400 mb-3">{{ fileName }}</p>

              <h4 class="uppercase font-semibold">
                {{ $t("attach_file") }}
                <span class="text-gray-400">({{ $t("max_size") }})</span>
              </h4>
            </div>
          </div>

          <!-- Privacy Notice & Submit -->
          <div class="w-full md:w-3/4 mx-auto">
            <h1 class="text-lg">
              {{ $t("ads_policy_pt_1") }}
              <a href="policy" class="text-blue-500">
                {{ $t("policy_title") }}</a
              >
              {{ $t("ads_policy_pt_2") }}
            </h1>
          </div>

          <div class="w-full flex py-6 justify-center pb-10 md:pb-24">
            <button
              @click="onReset"
              class="
                bg-red-700
                hover:bg-white
                text-white
                hover:text-red-700
                py-2
                mr-3
                px-4
                rounded
              "
              type="reset"
            >
              {{ $t("discard_btn") }}
            </button>
            <button
              @click="onSubmit"
              class="
                bg-blue-900
                hover:bg-white
                text-white
                hover:text-blue-900
                py-2
                px-4
                rounded
              "
              type="submit"
            >
              {{ $t("submit_btn") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import firebase from "firebase/app";
import axios from "axios";
import "firebase/functions";

export default {
  data: function() {
    return {
      loading: false,
      fileName: "",
      email: "",
      subject: "",
      ticket_description: "",
      priority: "",
      classification: "",
      attachment: "",
      industry: "",
      country: "",
      fullName: "",
      company: "",
      contactPhoneNumber: "",
      messages: ""
    };
  },
  methods: {
    onFileChange(e) {
      const fileData = e.target.files[0];
      this.fileName = fileData.name;
      this.fileObject = fileData;
      console.log(this.fileObject);
    },
    onReset() {
      this.fullName = "";
      this.email = "";
      this.subject = "";
      this.ticket_description = "";
      this.priority = "";
      this.classification = "";
      this.attachment = "";
      this.fileName = "";
    },
    async generateZohoAttachmentUrl(fileObject, zohoAccessToken) {
      let formData = new FormData();
      formData.append("attachment", fileObject);
      formData.append("zohoaccesstoken", zohoAccessToken);
      try {
        const zohoAttachmentResponse = await axios.post(
          "https://us-central1-shoot-dev-729e4.cloudfunctions.net/uploadZohoAttachment",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
        return zohoAttachmentResponse;
      } catch (error) {
        console.log("=== Axios Error ===");
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.statusText);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      }
    },
    onSubmit() {
      this.$validator
        .validate()
        .then(async valid => {
          if (!valid) {
            return;
          }
          this.loading = true;

          const generateZohoToken = firebase
            .app()
            .functions("us-central1")
            .httpsCallable("generateZohoToken");

          let zohoAccessToken;
          try {
            const response = await generateZohoToken();
            zohoAccessToken = response.data.access_token;
          } catch (error) {
            console.log(error);
            return;
          }

          // 1. Upload file to cloud function (uploadZohoAttachment)
          let zohoAttachmentResponse;
          if (this.fileObject) {
            zohoAttachmentResponse = await this.generateZohoAttachmentUrl(
              this.fileObject,
              zohoAccessToken
            );
          }

          // 2. Create a Zoho Ticket
          const createZohoTicket = firebase
            .app()
            .functions("us-central1")
            .httpsCallable("createZohoTicket");

          const ticketDetails = {
            subject: this.subject,
            contact: { lastName: this.fullName, email: this.email },
            email: this.email,
            description: this.ticket_description,
            priority: this.priority,
            classification: this.classification,
            uploads: zohoAttachmentResponse
              ? [zohoAttachmentResponse.data.id]
              : [],
            zohoaccesstoken: zohoAccessToken
          };

          await createZohoTicket(ticketDetails).then(() => {
            this.loading = false;
            this.$alert(
              "We will issue your inquiries to our support team.",
              "Thank You",
              "success"
            );
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style>
.bg-hero-blue {
  background: rgba(3, 52, 128, 1);
}
.loader {
  border-top-color: rgba(3, 52, 128, 1);
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
