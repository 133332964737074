// const firebaseConfig = {
//   apiKey: "AIzaSyB1Tk53Z_5s-6OcgAMfkUEVPDHo5NRmkqI",
//   authDomain: "shoot-dev-729e4.firebaseapp.com",
//   projectId: "shoot-dev-729e4",
//   storageBucket: "shoot-dev-729e4.appspot.com",
//   messagingSenderId: "914357570863",
//   appId: "1:914357570863:web:f0dd7b23f25cb2764025da",
//   measurementId: "G-2917PC0DGV",
// };
//production
const firebaseConfig = {
  apiKey: "AIzaSyAsRrd0LS0qcJrp_grSMY3pQTYxhN9Og-A",
  authDomain: "shoot-2c27b.firebaseapp.com",
  projectId: "shoot-2c27b",
  storageBucket: "shoot-2c27b.appspot.com",
  messagingSenderId: "1019667837537",
  appId: "1:1019667837537:web:0fd7bdcb8fc64b14f8b020",
  measurementId: "G-B1NC8DRZLB",
};
export default firebaseConfig;
