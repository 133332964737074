<template>
  <div
    id="contact"
    class="relative px-4 pt-20 justify-left"
  >
    <div class="flex flex-col">
      <h1 class="text-6xl text-white font-semibold text-center">
        {{ $t("contact_us") }}
      </h1>
      <div class="flex flex-col md:flex-row items-start justify-between mt-12">
        <div class="w-full px-4 py-6 lg:p-8 md:mx-2 lg:mx-4 mt-4 md:mt-0">
          <div class="pb-6">
            <label
              class="text-white block uppercase pb-1 text-xl font-semibold"
              for="full_name"
              >{{ $t("full_name") }}</label
            >
            <input
              type="text"
              v-model="name"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="name"
              class="border-current focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 py-3 pr-12 sm:text-sm border-black rounded-sm"
            />
            <span class="text-red-400 text-sm" v-show="errors.has('name')">{{
              errors.first("name")
            }}</span>
          </div>
          <div class="pb-6">
            <label
              class="text-white block text-xl pb-1 uppercase font-semibold"
              for="full_name"
              >{{ $t("mobile_no") }}</label
            >
            <VuePhoneNumberInput
              :no-flags="true"
              :only-countries="['MY']"
              :default-country-code="'MY'"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="contactPhoneNumber"
              v-model="contactPhoneNumber"
              class="pb-1"
            >
              <span
                class="text-red-400 text-sm"
                v-show="errors.has('contactPhoneNumber')"
              >
                {{ errors.first("contactPhoneNumber") }}
              </span>
            </VuePhoneNumberInput>
          </div>
          <div class="pb-6">
            <label
              class="text-white block text-xl pb-1 uppercase font-semibold"
              for="full_name"
              >{{ $t("gmail_address") }}</label
            >
            <input
              type="email"
              v-model="email"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="email"
              pattern="^[a-zA-Z0-9]+@gmail\.com$"
              class="border-current focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 py-3 pr-12 sm:text-sm border-gray-300 rounded-sm"
            />
            <span class="text-red-400 text-sm" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
          </div>
        </div>
        <div class="w-full px-4 py-6 lg:p-8 md:mx-2 lg:mx-4 mt-4 md:mt-0">
          <div class="pb-6">
            <label
              class="text-white block text-xl pb-1 uppercase font-semibold"
              for="full_name"
              >{{ $t("message") }}</label
            >
            <textarea
              class="border-current focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 pt-2 pb-2 pr-12 sm:text-sm border-gray-300 rounded-sm"
              name="messages"
              id=""
              cols="30"
              rows="12"
              v-model="messages"
              v-validate="'required'"
              data-vv-validate-on="blur"
              placeholder="Tell us about Shoot!"
            ></textarea>
            <span
              class="text-red-400 text-sm"
              v-show="errors.has('messages')"
              >{{ errors.first("messages") }}</span
            >
          </div>

          <div class="flex justify-end pb-5">
            <button
              @click="onsubmit"
              class="bg-yellow-500 uppercase rounded-lg text-xl font-semibold px-3 py-3 text-white"
              type="submit"
            >
              {{ $t("submit_btn") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  data: function () {
    return {
      name: "",
      email: "",
      contactPhoneNumber: "",
      messages: "",
    };
  },
  components: {
    VuePhoneNumberInput,
  },
  methods: {
    onsubmit() {
      this.$validator
        .validate()
        .then((valid) => {
          if (!valid) {
            return;
          }
          console.log(this.name);
          console.log(this.email);
          console.log(this.contactPhoneNumber);
          console.log(this.messages);
          firebase
            .firestore()
            .collection("campaign-contact-us")
            .add({
              full_name: this.name,
              email: this.email,
              contact_no: this.contactPhoneNumber,
              messages: this.messages,
              created_at: new Date(),
            })
            .then((result) => {
              console.log(result);
              this.$alert("We'll get back to you", "Success", "success");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>