<template>
  <div class="bg-local w-full">
    <!-- rounded-br-3xl rounded-bl-3xl -->
    <nav
      class="
        flex
        items-center
        bg-white
        justify-between
        flex-wrap
        bg-teal
        px-10
      "
    >
      <div class="flex items-center flex-no-shrink text-white mr-6">
        <a class="" href="/">
          <img
            class="object-scale-down h-20"
            src="@/assets/logo/shootBlue.png"
            alt=""
            width="120"
          />
        </a>
      </div>
      <div class="block sm:hidden">
        <button
          @click="toggle"
          class="
            flex
            items-center
            px-3
            py-2
            border
            rounded
            text-teal-lighter
            border-teal-light
            hover:text-white hover:border-white
          "
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        :class="open ? 'block' : 'hidden'"
        class="w-full flex-grow sm:flex sm:items-center sm:w-auto"
      >
        <div class="text-sm sm:flex-grow"></div>
        <div>
          <a
            class="
              uppercase
              px-4
              text-md text-blue-900
              font-bold
              block
              mt-4
              sm:inline-block sm:mt-0
              hover:text-blue-900
            "
            href="/#about"
            >{{ $t("about_us") }}</a
          >
          <a
            class="
              uppercase
              px-4
              text-md text-blue-900
              font-bold
              block
              mt-4
              sm:inline-block sm:mt-0
              hover:text-blue-900
            "
            href="/#feature"
            >{{ $t("features") }}</a
          >
          <a
            class="
              uppercase
              px-4
              text-md text-blue-900
              font-bold
              block
              mt-4
              sm:inline-block sm:mt-0
              hover:text-blue-900
            "
            href="/download"
            >{{ $t("download") }}</a
          >
          <a
            class="
              uppercase
              px-4
              text-md text-blue-900
              font-bold
              block
              mt-4
              sm:inline-block sm:mt-0
              hover:text-blue-900
            "
            href="/support"
            >{{ $t("support") }}</a
          >
          <a
            class="
              uppercase
              px-4
              text-md text-blue-900
              font-bold
              block
              mt-4
              sm:inline-block sm:mt-0
              hover:text-blue-900
            "
            href="/advertise"
            >{{ $t("advertise_with_us") }}</a
          >
          <a
            class="
              uppercase
              px-4
              text-md text-blue-900
              font-bold
              block
              mt-4
              sm:inline-block
              sm:mt-0
              hover:text-blue-900
            "
            href="/campaign"
            >{{ $t("campaign") }}</a
          >
          <!-- <div class="relative px-4 block mt-4  sm:mt-0">
            <div>
              <button
                @click="dropdown = !dropdown"
                class="
                  uppercase
                  font-bold
                  text-md text-blue-900
                  hover:text-blue-900
                  flex
                  items-center
                  space-x-1
                  focus:outline-none
                "
              >
                <span>{{ $t("campaign") }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <div
              class="
                absolute
                left-0
                z-20
                py-1
                mt-2
                bg-white
                shadow-xl
                lg:left-auto lg:right-0
              "
              :class="`origin-top-right absolute right-0 mt-2 w-48 shadow-lg transition ease-${
                dropdown ? 'out' : 'in'
              } duration-${dropdown ? '100' : '75'} transform opacity-${
                dropdown ? '100' : '0'
              } scale-${dropdown ? '100' : '95'}`"
            >
              <div class="w-full">
                <a
                  href="/campaign/spin-and-redeem"
                  class="
                    block
                    px-4
                    py-2
                    uppercase
                    font-bold
                    text-md text-blue-900
                    hover:text-blue-900
                    transition-colors
                    duration-200
                    transform
                  "
                >
                  Spin & Redeem
                </a>
              </div>
            </div>
          </div> -->

          <a
            class="
              px-4
              uppercase
              text-md
              font-bold
              text-white
              border-white
              p-2
              border-2
              no-underline
              rounded-md
              inline-block
              text-sm
              py-3
              leading-none
              bg-blue-900
              mt-4
              sm:mt-0
            "
            href="/contactUs"
            >{{ $t("contact_us") }}</a
          >
          <span class="pl-6 font-bold text-xl tracking-tight"
            ><toggle-button
              ref="toggleLang"
              color="#10B981"
              v-model="checked"
              @change="handleLanguageChange"
              :labels="{ checked: 'EN', unchecked: 'MY' }"
          /></span>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "Nav",
  data: function() {
    return {
      langs: ["my", "en"],
      open: false,
      checked: false,
      dropdown: false
    };
  },
  methods: {
    handleLanguageChange() {
      if (localStorage.getItem("langs") == "en") {
        localStorage.setItem("langs", "my");
        this.$i18n.locale = "my";
        this.checked = false;
      } else {
        localStorage.setItem("langs", "en");
        this.$i18n.locale = "en";
        this.checked = true;
      }
      window.location.reload;
    },
    toggle() {
      this.open = !this.open;
    }
  },
  created() {
    console.log(localStorage.getItem("langs"));
    if (localStorage.getItem("langs") == "en") {
      console.log("en");
      this.$i18n.locale = "en";
      this.checked = true;
    } else {
      localStorage.setItem("langs", "my");
      console.log("my");
      this.$i18n.locale = "my";
      this.checked = false;
    }
    window.location.reload;
  }
};
</script>
<style>
</style>