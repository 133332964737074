import Vue from "vue";
import Router from "vue-router";
import Home from "./screens/Home.vue";
import Guide from "./screens/Guide.vue";
import Terms from "./screens/Terms.vue";
import Policy from "./screens/Policy.vue";
import Advertise from "./screens/Advertise.vue";
import Campaign from "./screens/Campaign.vue";
import ContactUs from "./screens/ContactUs.vue";
import Support from "./screens/Support.vue";
// import Campaign from "./screens/Campaign.vue"; // Campaign has ended
import Download from "./screens/Download.vue";
import Campaign_Other from "./screens/CampaignOther.vue";
import Campaign_JTS from "./screens/CampaignJTS.vue";
import Campaign_Gshock from "./screens/CampaignGshock.vue";



Vue.use(Router);

const router = new Router({
  mode: "history",
  history: true,
  routes: [
    { path: "/", component: Home },
    { path: "/guide", component: Guide },
    { path: "/terms", component: Terms },
    { path: "/policy", component: Policy },
    { path: "/advertise", component: Advertise },
    { path: "/campaign", component: Campaign },
    { path: "/contactUs", component: ContactUs },
    { path: "/support", component: Support },
    { path: "/campaign", component: Campaign_Other },
    { path: "/campaign/spin-and-redeem", component: Campaign_Other},
    { path: "/campaign/jom-turun-stadium", component: Campaign_JTS},
    { path: "/campaign/menang-gshock", component: Campaign_Gshock},
    { path: "/download", component: Download },

  ],
});

export default router;
