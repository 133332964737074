<template>
  <div
    class=" bg-no-repeat bg-contain"
    style="background-image: url('/bg/Whitebackground.png')"
  >
    <Nav class="relative"></Nav>
    <FaqFull
      class="bg-fixed bg-no-repeat bg-cover"
    ></FaqFull>
    <Footer class="bg-hero-blue px-5"></Footer>
    <Copyright></Copyright>
  </div>
</template>

<script>

import Nav from '../components/Nav.vue';
import FaqFull from '../components/FaqFull.vue';
import Footer from '../components/Footer.vue';
import Copyright from '../components/Copyright.vue';

export default {
    components: {
        Nav,
        FaqFull,
        Footer,
        Copyright,
    }

}
</script>

<style>
.bg-hero-blue{
    background: rgba(3, 52, 128, 1);
  }
</style>