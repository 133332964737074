<template>
  <section>
    <div class="flex flex-col">
      <!-- Content -->
      <div class="flex items-center">
        <div class="flex flex-wrap flex-col md:flex-row items-center">
          <!--Left Col-->
          <div
            class="
              w-1/2
              pt-10
              md:mt-5
              lg:pl-24
              lg:-mr-52
              flex flex-col
              w-full
              md:w-3/6
              text-center
              md:text-left
            "
          >
            <img
              class="object-full w-full pb-6 px-6 md:px-0 object-right"
              src="@/assets/bg/shootDownload-1.png"
            />

            <!-- Google Play  -->
            <div class="flex mx-12 md:mb-12 md:justify-start justify-center">
              <div class="w-1/2 lg:w-2/4">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.ingres.shoot"
                >
                  <img
                    class="w-full mx-auto"
                    src="@/assets/logo/google-play-x.png"
                  />
                </a>
              </div>
              <div class="w-1/2 lg:w-2/4 ml-2 z-100">
                <a
                  target="_blank"
                  href="https://apps.apple.com/us/app/shoot-bola-sepak-malaysia/id1567181334"
                >
                  <img class="w-full mx-auto" src="@/assets/logo/ios.png" />
                </a>
              </div>
            </div>
          </div>
          <!--Right Col-->
          <div class="flex-1 md:w-3/6 pt-6 pr-6 items-right md:items-center">
            <img
              class="w-full my-20 md:my-32 object-right"
              src="@/assets/bg/shootDownload-2.png"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>

