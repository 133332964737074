<template>
  <div
    class="bg-scroll bg-no-repeat bg-contain"
    style="background-image: url('/bg/Whitebackground.png')"
  >
    <Nav></Nav>
    <Tnc class="px-5"></Tnc>
    <TermsContent class="px-5"></TermsContent>
    <Footer class="bg-hero-blue px-5"></Footer>
    <Copyright></Copyright>
  </div>
</template>

<script>

import Nav from '../components/Nav.vue';
import Footer from '../components/Footer.vue';
import Copyright from '../components/Copyright.vue';
import Tnc from '../components/Tnc.vue';
import TermsContent from '../components/TermsContent.vue';
export default {
    components: {
        Nav,
        Footer,
        Copyright,
        Tnc,
        TermsContent
    }

}
</script>

<style>
.bg-hero-blue{
    background: rgba(3, 52, 128, 1);
  }
</style>