<template>
  <div class="w-full h-auto bg-white">
    <div class="flex flex-col px-4 lg:px-24 md:px-8">
      <div id="advertiseWithUs" class="pt-20 text-center pb-10">
        <!-- Title -->
        <h1 class="text-5xl font-semibold text-center pb-5">
          {{ $t("advertise_content_1_title") }}
        </h1>
        <!-- Content 1 -->
        <h1 class="pb-10 font-regular text-xl">
          {{ $t("advertise_content_1_description_1") }}
        </h1>
        <!-- Content 2 -->
        <h1 class="pb-10 font-regular text-xl">
          {{ $t("advertise_content_1_description_2") }}
        </h1>
        <!-- Content 3 -->
        <h1 class="pb-10 font-regular text-xl">
          {{ $t("advertise_content_1_description_3") }}
        </h1>
      </div>
      <div class="mx-5 py-5">
        <div
          class="container mx-auto w-full h-auto bg-white rounded-3xl shadow-2xl"
        >
          <div class="p-7 flex flex-col">
            <div class="items-center">
              <h1 class="text-center text-4xl text-gray-700 font-bold">
                {{ $t("advertise_content_2_title") }}
              </h1>
            </div>
            <div class="grid grid-rows lg:grid-cols-4 gap-4 pt-24 pb-16">
              <!-- Mobile ads 1 -->
              <div class="flex flex-col items-center w-full">
                <div
                  class="rounded-full flex bg-gray-200 h-32 w-32 items-center justify-center"
                >
                  <!-- svg -->
                  <svg
                    class="h-24 w-24"
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 275.87 567.96"
                  >
                    <title>Shoot!-UI</title>
                    <g id="_8fAebi" data-name="8fAebi">
                      <path
                        d="M.06,284.51Q.06,167.77.08,51C.11,26,16.82,5.63,41.17.87A57.87,57.87,0,0,1,52.11.05q85.48-.06,171,0c31.36,0,52.8,21.56,52.79,52.78q0,231.72-.09,463.45c0,30.54-21.66,52.24-52.29,52.26q-86,0-172-.07C21.9,568.43,0,546.55,0,517Q0,400.75,0,284.51ZM18.26,92.37V466.09H257.52V92.37ZM111.81,519a26.13,26.13,0,1,0,25.92-26.26A26.25,26.25,0,0,0,111.81,519Zm26-463.65c19,0,38,0,57,0,3.63,0,6.49-1.12,6.57-5.11S198.68,45,195,45Q138,45,81,45c-1.57,0-3.69.23-4.58,1.22-1.14,1.28-2.3,4.24-1.65,5.06,1.4,1.8,3.93,3.78,6,3.82C99.83,55.41,118.82,55.29,137.81,55.3Z"
                      />
                    </g>
                    <polygon
                      points="113.62 400.74 119.7 400.74 116.64 391.7 113.62 400.74"
                    />
                    <path
                      d="M141,395.08c-2.25,0-4.29,1.69-4.29,4.82s2,4.89,4.29,4.89,4.3-1.72,4.3-4.85S143.3,395.08,141,395.08Z"
                    />
                    <path
                      d="M25.35,373.35v49.53H250.53V373.35Zm97.38,36.4-1.48-4.36H112l-1.47,4.36h-6.3l8.93-24.69h7l8.93,24.69Zm28.59,0h-6v-2.82a6.8,6.8,0,0,1-6.05,3.1c-4.82,0-8.66-3.94-8.66-10.13s3.84-10.06,8.66-10.06a7.22,7.22,0,0,1,6.05,3v-9.11h6Zm12.34.28c-5.24,0-8.75-2.92-9-6.72h5.95a2.76,2.76,0,0,0,3,2.29c1.55,0,2.36-.71,2.36-1.58,0-3.17-10.69-.88-10.69-8.09,0-3.35,2.85-6.09,8-6.09s7.88,2.81,8.27,6.68H166a2.6,2.6,0,0,0-2.89-2.21c-1.4,0-2.18.56-2.18,1.51,0,3.13,10.63.91,10.73,8.23C171.61,407.46,168.59,410,163.66,410Z"
                    />
                  </svg>
                </div>
                <div>
                  <h1
                    class="text-gray-600 text-xl font-semibold text-center pt-5"
                  >
                    Display Advertising
                  </h1>
                </div>
              </div>
              <!-- Mobile ads 2 -->
              <div class="flex items-center flex-col">
                <div
                  class="rounded-full bg-gray-200 h-32 w-32 flex items-center justify-center"
                >
                  <svg
                    class="h-20 w-20"
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 486.26 430.75"
                  >
                    <title>Shoot!-UI</title>
                    <rect x="5.22" width="183.44" height="183.86" rx="29.33" />
                    <path
                      d="M475.76,70.1H227a10.5,10.5,0,0,1,0-21H475.76a10.5,10.5,0,1,1,0,21Z"
                    />
                    <path
                      d="M475.76,134.77H227a10.5,10.5,0,1,1,0-21H475.76a10.5,10.5,0,0,1,0,21Z"
                    />
                    <rect
                      x="5.22"
                      y="246.88"
                      width="183.44"
                      height="183.86"
                      rx="29.33"
                    />
                    <path
                      d="M475.76,317H227a10.5,10.5,0,0,1,0-21H475.76a10.5,10.5,0,0,1,0,21Z"
                    />
                    <path
                      d="M475.76,381.65H227a10.5,10.5,0,1,1,0-21H475.76a10.5,10.5,0,0,1,0,21Z"
                    />
                  </svg>
                </div>
                <div>
                  <h1
                    class="text-gray-600 text-xl font-semibold text-center pt-5"
                  >
                    Native Advertising
                  </h1>
                </div>
              </div>
              <!-- Mobile ads 3 -->
              <div class="flex items-center flex-col">
                <div
                  class="rounded-full bg-gray-200 h-32 w-32 flex items-center justify-center"
                >
                  <svg
                    class="h-20 w-20"
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 639.15 449.35"
                  >
                    <title>Shoot!-UI</title>
                    <g id="_3An6CJ.tif" data-name="3An6CJ.tif">
                      <path
                        d="M0,249.78V198.89A48,48,0,0,0,.71,194c.82-16.44,1.31-32.9,2.41-49.32,1.66-24.71,3.54-49.48,10-73.47,8.31-30.71,28-51.11,59.05-58.86,26.89-6.71,54.68-7.54,82.24-8.76C195.15,1.8,235.92,0,276.67,0Q370.5,0,464.33,2.73c31.69,1,63.41,4,94.91,7.87,34.81,4.25,59,23.46,69.54,57.36,6.92,22.28,9.88,45.55,10.11,68.84.53,54,.08,108.09.2,162.14a315.79,315.79,0,0,1-9.28,78.5C622,408.36,602,428.61,570.89,436.25c-27.41,6.73-55.67,7.61-83.73,8.86-37.57,1.67-75.17,2.39-112.76,3.56-2,.06-3.93.44-5.9.68H274.63a47.89,47.89,0,0,0-4.89-.68c-36.4-1.15-72.85-1.58-109.21-3.61-27.51-1.53-55.15-3.65-82.3-8.11-34.79-5.7-57-27.14-65.59-61.5C5.49,346.8,3.83,317.38,2.06,288,1.3,275.28.68,262.52,0,249.78Zm425-25.45L257.75,128.06V320.61Z"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <h1
                    class="text-gray-600 text-xl font-semibold text-center pt-5"
                  >
                    In Stream &#38; Out Stream Video Advertising
                  </h1>
                </div>
              </div>
              <!-- Mobile ads 4 -->
              <div class="flex items-center flex-col">
                <div
                  class="rounded-full bg-gray-200 h-32 w-32 flex items-center justify-center"
                >
                  <svg
                    class="h-20 w-20"
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 744.19 470.89"
                  >
                    <title>Shoot!-UI</title>
                    <g id="_0MQmmW.tif" data-name="0MQmmW.tif">
                      <path
                        d="M0,214.67c1.47-2.46,3-4.89,4.39-7.38q54.47-97.41,109-194.83c2-3.51,4.45-6.74,6.6-10.16,1.58-2.5,3.33-3,6-1.38,6.71,4,13.63,7.73,20.27,11.88,10.51,6.55,20.8,13.45,31.3,20,3.34,2.09,3.9,3.94,1.9,7.54-21.52,38.62-42.65,77.46-64.32,116-16.72,29.72-33.9,59.2-51.22,88.58-5.54,9.4-4.22,9.15-13.68,3.78C35.22,240.23,20.67,231.07,6,222.08c-2.22-1.36-4-3.41-6-5.13Z"
                      />
                      <path
                        d="M181.71,70.43c21.93,17.92,49,20,76,24.85C256,98,254.81,100,253.52,102c-9.79,14.59-19.76,29.06-29.34,43.78-7,10.75-11.21,22.29-11.28,35.57-.05,11.29,10,20.43,20,21.33,21,1.88,39.68-4.35,57.22-15.14,16.08-9.9,29-23.16,40.2-38.18A18.33,18.33,0,0,1,341.29,142c5.88-1.44,11.58-3.59,17.47-5a10,10,0,0,1,6.31.63c67,33.05,129.28,73.75,188.44,119.26,14.63,11.25,28.89,23,43.15,34.71,6,4.91,10.36,10.92,11.19,19.11C609,321.92,601.5,338,585.78,338c-3.67,0-7.35.6-11,.4a16.94,16.94,0,0,1-7.42-1.9c-34.16-20.2-68.21-40.6-102.37-60.8a129.54,129.54,0,0,0-15.34-7.38c-2.92-1.25-6-1.46-7.7,2.28s-1.25,6.15,2.37,8.43q29.85,18.79,59.83,37.35c15.93,9.8,32,19.36,48.08,28.95,1.94,1.15,3,2.39,2.88,4.75s.18,4.55.21,6.82c.17,14-9.24,24.58-23.14,26.32-8.29,1-16-.06-23.38-4.3-28-16-56.05-31.81-84.1-47.68-1.43-.81-2.88-1.58-4.34-2.34-4.6-2.39-10-.35-11.27,4.68-.39,1.57.61,4,1.76,5.3a22.26,22.26,0,0,0,5.75,4.24q37.69,22.31,75.41,44.57a106.08,106.08,0,0,1,10.25,7.14c3.37,2.58,4.41,6,3,10.13-.92,2.62-1.54,5.36-2.67,7.88-3.45,7.68-9.29,12.28-17.71,13.84-10.05,1.86-19.39-.29-28.37-4.47-20.5-9.54-40.91-19.26-61.39-28.85a83.42,83.42,0,0,0-9.48-3.82c-3.66-1.19-6.74.44-8.3,4s-.71,6.66,3,8.55c19,9.67,38.13,19.22,57.19,28.84,4.17,2.11,8.25,4.41,12.41,6.53,2.16,1.1,2.32,2.44.87,4.18-9.6,11.51-20.47,20.5-36.64,20.61-23.66.16-46.05-5.53-68-13.68a25.52,25.52,0,0,1-2.45-1.3c1.24-2.43,2.15-4.93,3.64-7,17.78-24.78.87-59.17-28.38-65.41-3.19-.68-6.41-1.18-10-1.83a42.33,42.33,0,0,0-10.54-35.21c-8.53-9.69-19.24-15.88-32.83-15.46-.44-6-.42-11.79-1.36-17.47-4-23.85-29-39.77-52-33.39a32.35,32.35,0,0,0-16.67,11.3c-3.11,3.82-6,7.81-9.34,12.16-1.36-2.59-2.53-4.91-3.78-7.17-7.65-13.86-18.95-22.51-35-24a34.85,34.85,0,0,0-24.27,6.88c-2.43,1.76-4.84,3.54-7.49,5.48-1.14-1.31-2.06-2.32-2.92-3.38-8.34-10.18-16.64-20.38-25-30.52-1.22-1.47-1.91-2.49-.75-4.54Q133.75,155,180.39,72.31a8.44,8.44,0,0,1,.66-.91Z"
                      />
                      <path
                        d="M229.12,175.4c-.49-5.12,1.47-10.6,4.64-15.82,17-27.89,36.06-54.34,55.7-80.41,4.82-6.4,11.71-9.38,19.05-10.79,16.61-3.2,33.26-6.81,50.06-8.21,20.89-1.74,41.94-1.77,62.93-1.88,21.77-.12,42.85,5,64,9.46,14,2.91,28.05,5.9,42.21,6.8,9.74.61,19.73-2.15,29.55-3.74,3.78-.61,6-.21,8.14,3.58q44.46,79.17,89.29,158.12c.83,1.47,2.2,2.76.21,4.57-11.48,10.47-22.75,21.19-34.39,31.48-3.55,3.14-8.14,5.07-12.08,7.8-2.35,1.63-3.89,1.05-5.91-.62A1460.73,1460.73,0,0,0,477.8,184.21c-34.45-22.69-70.51-42.61-107.46-60.93-5.06-2.51-9.8-3.16-15.23-1.31-9.79,3.35-19.78,6.13-29.59,9.43-1.68.57-3.21,2.37-4.24,4a133.32,133.32,0,0,1-43.72,41.92A76,76,0,0,1,240.46,188a21.67,21.67,0,0,1-3.78-.18c-6-.89-7.51-2.72-7.56-8.72Z"
                      />
                      <path
                        d="M744.19,217.15a15.12,15.12,0,0,1-2.3,2.41c-5,3.47-9.84,7.12-15,10.22q-18.08,10.72-36.46,21c-5,2.78-5.28,2.58-8.09-2.33-24.95-43.65-50-87.25-74.71-131-14.28-25.29-28-50.9-42-76.39-2.56-4.68-2.67-5.51,1.86-8.36,17-10.69,34.2-21,51.29-31.57,3.06-1.89,4.07.34,5.22,2.26,10.35,17.37,21,34.56,30.91,52.17,28,49.77,55.68,99.7,83.46,149.58,1.71,3.09,3.2,6.31,4.77,9.48C743.48,215.21,743.71,215.94,744.19,217.15Z"
                      />
                      <path
                        d="M250.07,315.47c.53,3.6-2,7.47-4.64,11.18q-15.83,22-31.83,43.89c-5.24,7.12-10.82,14-16.21,21-8.08,10.55-19.51,9.59-28.5,4.35-8.8-5.12-14.26-13-14.58-23.39a19.63,19.63,0,0,1,3.16-11.32q24.48-34,49.72-67.43c7.95-10.47,18.26-11.6,29.19-4.15C245,295.46,249.66,303.61,250.07,315.47Z"
                      />
                      <path
                        d="M237.73,437.26c-16.26-.59-28.83-13.65-28.1-28.94.16-3.26,1-7,2.92-9.59,14.15-19.22,28.58-38.24,43.06-57.21,3.55-4.66,8.93-4.44,14-3.63a28.27,28.27,0,0,1,24,30.21,18.83,18.83,0,0,1-3.18,9.14Q272,403.19,253,428.75C249.15,433.92,244.34,438.15,237.73,437.26Z"
                      />
                      <path
                        d="M340.2,417.87c-1.08,3-1.55,6.52-3.35,9-9.21,12.8-18.49,25.56-28.29,37.91-5.37,6.78-13.13,7.34-20.76,4.33-10.46-4.12-17.31-11.91-19.26-23-.65-3.68-.15-8.58,1.86-11.54,9.27-13.67,19.12-27,29.05-40.16,3.27-4.35,8.4-5.42,13.68-5.08C326.43,390.17,339.61,404.35,340.2,417.87Z"
                      />
                      <path
                        d="M172.21,307.71c-1.1,3.3-1.49,7.09-3.41,9.81-6,8.54-12.41,16.88-19.1,24.92-4.85,5.83-11.44,7.12-18.61,4.64-11.39-3.94-18.64-11.71-21.31-23.55-1-4.35-.63-8.71,1.93-12.4q9-13.06,18.56-25.8c5.08-6.81,12.25-7.58,19.83-5.67C162.91,282.87,171.36,294.05,172.21,307.71Z"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <h1
                    class="text-gray-600 text-xl font-semibold text-center pt-5"
                  >
                    Custom Collaboration / Branded Content
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
section {
  background: rgba(3, 52, 128, 1);
}
</style>
